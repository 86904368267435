import { Minus, Plus } from "iconoir-react";
import { CreateInvoiceDto, InvoiceServiceDto, PartnerInvoiceDto } from "models/CreateInvoice.dto";
import { InvoiceType } from "models/InvoiceType";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { YupDate, YupNumber, YupString, YupValidator } from "shared/src/common/yup";
import { ButtonIcon } from "shared/src/components/ButtonIcon/ButtonIcon";
import { CommonTable, TableItem } from "shared/src/components/CommonTable/CommonTable";
import { CntrDate, CntrText } from "shared/src/components/ControlledElements/CntrlWrap";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { RDCFooter, RightDrawer } from "shared/src/components/RightDrawer/RightDrawer";
import { removeArrayElement } from "shared/src/helpers/utils";
import * as yup from "yup";
import { SchemaOf } from "yup";
import styles from "./CreateInvoice.module.scss";

const newService: InvoiceServiceDto = {
  name: "",
  qty: 1,
  cost: 0,
};

const CreateInvoiceSchema: SchemaOf<CreateInvoiceDto | PartnerInvoiceDto> = yup.object({
  services: yup.array().of(
    yup.object({
      name: YupString().required().label("Name of service"),
      qty: YupNumber().required().label("QTY").min(1),
      cost: YupNumber().required().label("Cost").min(0),
    })
  ),
  issued: YupDate().max(yup.ref("due"), `Issued Date cannot be greater than Due Date`).required(),
  due: YupDate().required(),
  vat: YupNumber().required().max(100).min(0),
  email: YupString().email().label("Email"),
});

type CreateInvoiceProps = {
  userId: string;
  active: boolean;
  onClose: () => void;
  pending: boolean;
  invoiceType: InvoiceType;
  onSubmit?: (formData: CreateInvoiceDto | PartnerInvoiceDto) => void;
};

export const CreateInvoice: React.FC<CreateInvoiceProps> = (props) => {
  const { active, onClose, onSubmit, pending, invoiceType } = props;

  const { control, trigger, watch, setValue, getValues, reset } = useForm<CreateInvoiceDto>({
    resolver: YupValidator(CreateInvoiceSchema),
    defaultValues: {
      services: [newService],
      vat: 0,
    },
  });
  const services = watch("services");

  const items: TableItem<InvoiceServiceDto>[] = [
    { label: "Description", render: (x) => (x.name.length ? x.name : "N/A") },
    { label: "QTY", render: (x) => x.qty },
    { label: "Cost", render: (x) => x.cost },
    { label: "Amount", render: (x) => x.qty * x.cost },
  ];

  const issuedDate = watch("issued");

  const submit = async () => {
    if (!(await trigger())) return;
    let data = getValues();
    onSubmit?.(data);
  };

  const onAdd = () => {
    setValue("services", [...services, newService]);
  };
  const onRemove = (idx: number) => {
    setValue("services", removeArrayElement(services, idx));
  };

  useEffect(() => {
    if (!active) reset();
  }, [active]);

  return (
    <RightDrawer
      open={active}
      onClose={onClose}
      title="Create Invoice"
      footer={<RDCFooter pending={pending} onClose={onClose} submit={submit} />}
    >
      <form className={styles.form}>
        <CommonTable items={items} idKey="name" values={services} />

        {services.map((_, idx) => (
          <div className={styles.formService} key={idx}>
            <div className={styles.formRow}>
              <CntrText label="Name of service" control={control} name={`services.${idx}.name`} type="text" required />
              {services.length > 1 && (
                <ButtonIcon className={styles.removeBtn} onClick={() => onRemove(idx)} size={50} icon={<Minus />} />
              )}
            </div>

            <div className={styles.formRow}>
              <CntrText
                label="Service cost, $"
                control={control}
                name={`services.${idx}.cost`}
                typeState="number"
                required
              />
              <CntrText label="QTY" control={control} name={`services.${idx}.qty`} typeState="number" required />
            </div>
          </div>
        ))}

        <div className={styles.formRow}>
          <CntrDate required label="Issued Date" control={control} name="issued" />
          <CntrDate minDate={issuedDate} required label="Due Date" control={control} name="due" />
        </div>
        {invoiceType === InvoiceType.partner && (
          <CntrText label="Partner email" control={control} name="email" type="text" required />
        )}
        <CntrText required label="VAT, %" control={control} name="vat" typeState="number" />

        <PbButton onClick={onAdd} type="button" icon={<Plus />} color="secondary">
          Add Service
        </PbButton>
      </form>
    </RightDrawer>
  );
};
