import { PayloadAction } from "@reduxjs/toolkit";
import { usersPageApi } from "api/UsersPageApi";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { getUserPageUserInfo } from "redux/userPage/userPageActions";
import { parseError } from "shared/src/helpers/errorHandler";

export function* freezeUserSaga(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;
  try {
    yield call([usersPageApi, usersPageApi.freezeUser], id);
    yield put(getUserPageUserInfo({ id }));
    yield call([toast, toast.success], "User frozen");
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
