import { call, put } from "redux-saga/effects";
import { deleteToken } from "shared/src/helpers/token";
import { setInitialState } from "../userSlice";

export function* logOutSaga() {
  try {
    yield call(deleteToken);
    yield put(setInitialState());
  } catch (e) {
    console.error(e);
  }
}
