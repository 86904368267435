import clsx from "clsx";
import { range } from "lodash";
import React from "react";
import { ArrowLeft, ArrowRight } from "../../images/Arrows";
import { PaginationInfo } from "../../models/Pagination";
import styles from "./PageSwitch.module.scss";

// https://stackoverflow.com/a/70263913
const pagination = (page: number, total: number, len = 5) => {
  const { max, min, floor } = Math;
  total = max(total, page);
  const start = max(1, min(page - floor((len - 3) / 2), total - len + 2));
  const end = min(total, max(page + floor((len - 2) / 2), len - 1));
  return [
    ...(start > 2 ? [1, null] : start > 1 ? [1] : []),
    ...range(start, end + 1),
    ...(end < total - 1 ? [null, total] : end < total ? [total] : []),
  ];
};

type PageSwitchProps = {
  pageInfo: PaginationInfo;
  className?: string;
  onChange?: (page: number) => void;
};

// todo make change per page component
export const PageSwitch: React.FC<PageSwitchProps> = (props) => {
  const { pageInfo, onChange, className } = props;
  const { totalCount, page, perPage, pagesCount } = pageInfo;

  const pages = pagination(page, pagesCount);

  if (perPage >= totalCount) return null;
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.flex}>
        <button disabled={page <= 1} onClick={() => onChange?.(page - 1)} className={styles.button}>
          <ArrowLeft />
        </button>

        {pages.map((x, idx) => {
          if (x === null)
            return (
              <div key={idx} className={clsx(styles.button, styles.buttonPoints)}>
                ...
              </div>
            );

          return (
            <button
              onClick={() => onChange?.(x)}
              key={idx}
              className={clsx(styles.button, page === x && styles.buttonActive)}
            >
              {x}
            </button>
          );
        })}

        <button disabled={page >= pagesCount} onClick={() => onChange?.(page + 1)} className={styles.button}>
          <ArrowRight />
        </button>
      </div>
    </div>
  );
};
