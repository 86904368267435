import clsx from "clsx";
import { format } from "date-fns";
import { EditPencil } from "iconoir-react";
import styles from "pages/UserInfo/userInfo.module.scss";
import React from "react";
import { Heading } from "shared/src/components/Heading/Heading";
import { CompanyDto } from "shared/src/models/Company";
import { UserStatusEnum } from "shared/src/models/UserModel";

type ProfileOverviewProps = {
  name?: string;
  lastName?: string;
  email?: string;
  createdAt: Date;
  status: UserStatusEnum;
  openEdit: (boolean) => void;
  company?: CompanyDto;
};

export const ProfileOverview: React.FC<ProfileOverviewProps> = ({
  name,
  lastName,
  email,
  status,
  openEdit,
  createdAt,
  company,
}) => {
  return (
    <div className={styles.profileOverview}>
      <div className={styles.profileOverviewHead}>
        <Heading variant="h2">Profile Overview</Heading>
        <EditPencil className={styles.editProfile} color="#6E7E9D" onClick={() => openEdit(true)} />
      </div>
      <div className={styles.tablesWrap}>
        <div className={styles.profileTable}>
          <table className={styles.profileOverviewTable}>
            <tbody>
              <tr>
                <td className={styles.leftPartTableColor}>User Name</td>
                <td>
                  {name} {lastName}
                </td>
              </tr>
              <tr>
                <td className={styles.leftPartTableColor}>Email</td>
                <td>{email} </td>
              </tr>
              <tr>
                <td className={styles.leftPartTableColor}>Profile</td>
                <td>
                  <span className={clsx(styles[status])}>{status}</span>
                </td>
              </tr>
              <tr>
                <td className={styles.leftPartTableColor}>Registration</td>
                <td>
                  <span>{format(createdAt, "P")}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {company?.name && (
          <div className={styles.profileTable}>
            <table className={styles.profileOverviewTable}>
              <tbody>
                <tr>
                  <td className={styles.leftPartTableColor}>Company name</td>
                  <td>{company.name}</td>
                </tr>
                <tr>
                  <td className={styles.leftPartTableColor}>Country</td>
                  <td>{company.country}</td>
                </tr>
                <tr>
                  <td className={styles.leftPartTableColor}>Phone</td>
                  <td>{company.phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
