import { format } from "date-fns";
import React, { useRef, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { useOnClickOutside } from "shared/src/hooks/useOnClickOutside";
import styles from "./rangeDatePicker.module.scss";

const now = new Date();

type RangeDatePickerProps = {
  range?: DateRange;
  setRange?: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
};
export const RangeDatePicker: React.FC<RangeDatePickerProps> = ({ range, setRange }) => {
  const dropRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  useOnClickOutside(rootRef, () => setOpen(false));

  let footer = "";
  if (range?.from) {
    if (!range.to) {
      footer = format(range.from, "yyyy-MM-dd");
    } else if (range.to) {
      footer = `${format(range.from, "yyyy-MM-dd")} - ${format(range.to, "yyyy-MM-dd")}`;
    }
  }
  return (
    <div ref={rootRef} className={styles.container}>
      <TextInput onClick={() => setOpen(true)} value={footer} label="Set plan duration" />
      {open && (
        <div className={styles.dayPicker} ref={dropRef}>
          <DayPicker
            id="day-picker"
            mode="range"
            defaultMonth={now}
            selected={range}
            onSelect={setRange}
            modifiersStyles={{
              selected: { backgroundColor: "#41a8ed" },
            }}
          />
        </div>
      )}
    </div>
  );
};
