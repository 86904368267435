import { dashClient } from "helpers/HttpClient";
import { TariffDto } from "models/TariffDto";
import { BaseRestClient } from "shared/src/http";
import { TariffCatalogDto } from "shared/src/models/TariffCatalogDto";

export class CatalogApi {
  dashClient: BaseRestClient;

  constructor() {
    this.dashClient = dashClient;
  }

  async getTarriffsData(): Promise<TariffDto[]> {
    const rep = await this.dashClient.apiGet<TariffDto[]>({ url: "/tariff/all" });
    return rep;
  }

  async getTarriffCatalog(): Promise<TariffCatalogDto[]> {
    const rep = await this.dashClient.apiGet<TariffCatalogDto[]>({ url: "/admin/tariffs/catalogs" });
    return rep;
  }

  async getDataVolumesCatalog(): Promise<string[]> {
    const rep = await this.dashClient.apiGet<string[]>({ url: "/catalogs/data-volumes" });
    return rep;
  }
}

export const catalogApi = new CatalogApi();
