import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectCatalogState } from "./selectCatalogState";

export const selectDataVolumesCatalog = createSelector([selectCatalogState], (state) => state.dataVolumesCatalog);

export const useDataVolumeOptions = () => {
  const dataVolumes = useSelector(selectDataVolumesCatalog);

  return dataVolumes.map((x) => ({
    label: x,
    value: x,
  }));
};
