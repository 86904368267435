import React from "react";

type PetaboxLogoProps = {
  short?: boolean;
} & React.SVGProps<SVGSVGElement>;

export const PetaboxLogo: React.FC<PetaboxLogoProps> = ({ short = false, ...svgProps }) => {
  if (short) {
    return (
      <svg height="24" viewBox="0 0 540 102" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
        <path
          d="M33.122 67.7724H79.2197V82.3268H31.8501C27.6668 82.3268 23.5244 81.5032 19.6596 79.9031C15.7948 78.3029 12.2833 75.9576 9.32566 73.0009C6.36799 70.0443 4.02211 66.5344 2.422 62.6716C0.821883 58.8088 -0.00112009 54.6688 1.14411e-06 50.488H15.8525C15.8491 52.7566 16.2933 55.0037 17.1597 57.1006C18.0261 59.1974 19.2976 61.1031 20.9015 62.7084C22.5055 64.3137 24.4104 65.5873 26.5073 66.4562C28.6042 67.3252 30.852 67.7724 33.122 67.7724Z"
          fillRule="evenodd"
        />
        <path
          d="M95.021 67.7723H79.2112C79.2094 63.4295 77.5695 59.247 74.6186 56.0592C71.6677 52.8713 67.6226 50.9124 63.2904 50.5732C63.1094 46.5138 61.3703 42.6802 58.4346 39.8688C55.4989 37.0575 51.5922 35.4846 47.5262 35.4769C43.4603 35.4692 39.5477 37.0273 36.6014 39.8276C33.655 42.6278 31.9014 46.4548 31.705 50.5135H15.8525C16.2491 43.5028 18.9659 36.8227 23.5757 31.5235C28.1855 26.2243 34.427 22.6065 41.3186 21.239C48.2103 19.8715 55.3613 20.8318 61.6473 23.969C67.9332 27.1062 72.9976 32.2422 76.0441 38.5697C81.696 41.0891 86.4962 45.1913 89.8641 50.38C93.232 55.5688 95.0232 61.6217 95.021 67.8064V67.7723Z"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg height="24" viewBox="0 0 540 102" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        d="M144.269 102V23.8022H157.33V30.6272C159.275 28.157 161.755 26.1591 164.583 24.7835C167.411 23.4079 170.515 22.6903 173.66 22.6846C188.565 22.6846 199.782 34.3213 199.782 50.9658C199.782 67.6104 188.565 79.2471 173.66 79.2471C170.515 79.2414 167.411 78.5238 164.583 77.1482C161.755 75.7726 159.275 73.7747 157.33 71.3044V101.966L144.269 102ZM186.721 51C186.721 41.206 180.404 34.6796 172.021 34.6796C163.638 34.6796 157.33 41.206 157.33 51C157.33 60.7939 163.638 67.3118 172.021 67.3118C180.404 67.3118 186.73 60.7854 186.73 51H186.721Z"
        fillRule="evenodd"
      />
      <path
        d="M218.068 55.351C219.485 63.2936 224.709 67.8578 232.759 67.8578C238.53 67.8578 241.902 65.6823 243.643 61.8859H257.805C254.536 73.1984 245.069 79.2898 232.196 79.2898C216.625 79.2898 205.194 67.6531 205.194 51.0085C205.194 34.364 216.625 22.7273 232.196 22.7273C247.766 22.7273 259.188 34.364 259.188 51.0085C259.188 52.5271 259.077 53.9433 258.966 55.3595L218.068 55.351ZM218.179 46.103H246.255C244.847 38.4931 239.836 34.1592 232.221 34.1592C224.607 34.1592 219.587 38.5102 218.179 46.103Z"
        fillRule="evenodd"
      />
      <path
        d="M292.951 67.3117C294.981 67.3039 296.998 66.9756 298.926 66.3392V77.9759C296.137 78.8172 293.243 79.2567 290.33 79.2812C279.548 79.2812 271.711 75.0155 271.711 60.4611V35.2256H263.013V23.8022H271.72V8.8468H284.781V23.8022H298.935V35.2256H284.781V59.5909C284.781 64.9571 287.291 67.3117 292.951 67.3117Z"
        fillRule="evenodd"
      />
      <path
        d="M306.003 63.6178C306.003 40.2335 341.379 50.6758 341.379 39.7984C341.379 35.5328 337.025 33.0502 331.365 33.0502C325.39 33.0502 321.241 36.2068 321.241 42.2981H307.642C307.642 28.1532 319.175 22.7188 331.365 22.7188C343.316 22.7188 354.414 27.6157 354.414 40.3359V78.1892H341.609V70.1442C338.126 76.5598 331.809 79.2812 324.297 79.2812C314.932 79.2812 306.003 74.6061 306.003 63.6178ZM327.012 68.3697C334.635 68.3697 341.601 64.0188 341.601 55.7519V53.2523C332.893 56.8354 319.405 55.7519 319.405 62.7135C319.397 66.5526 322.683 68.3697 327.012 68.3697Z"
        fillRule="evenodd"
      />
      <path
        d="M421.922 51.0001C421.922 67.6446 410.705 79.2813 395.8 79.2813C392.654 79.2743 389.552 78.5562 386.723 77.1807C383.895 75.8052 381.415 73.808 379.469 71.3387V78.1637H366.408V0H379.469V30.6615C381.415 28.1921 383.895 26.1949 386.723 24.8194C389.552 23.4439 392.654 22.7258 395.8 22.7188C410.705 22.7188 421.922 34.3555 421.922 51.0001ZM379.469 51.0001C379.469 60.7855 385.786 67.3119 394.161 67.3119C402.535 67.3119 408.903 60.7855 408.903 51.0001C408.903 41.2147 402.586 34.6797 394.203 34.6797C385.82 34.6797 379.469 41.2061 379.469 51.0001Z"
        fillRule="evenodd"
      />
      <path
        d="M427.359 51C427.359 34.3555 439.217 22.7188 455.112 22.7188C471.007 22.7188 482.864 34.3555 482.864 51C482.864 67.6446 470.999 79.2812 455.112 79.2812C439.225 79.2812 427.359 67.6446 427.359 51ZM469.803 51C469.803 41.2061 463.495 34.6796 455.112 34.6796C446.729 34.6796 440.42 41.2061 440.42 51C440.42 60.7939 446.729 67.3118 455.112 67.3118C463.495 67.3118 469.803 60.7854 469.803 51Z"
        fillRule="evenodd"
      />
      <path
        d="M483.402 78.1893L503.976 50.1299L484.495 23.8023H499.733L512.248 40.6687L523.678 23.8023H538.916L519.435 50.4541L540 78.1893H524.762L511.052 59.702L498.64 78.1893H483.402Z"
        fillRule="evenodd"
      />
      <path
        d="M33.122 67.7724H79.2197V82.3268H31.8501C27.6668 82.3268 23.5244 81.5032 19.6596 79.9031C15.7948 78.3029 12.2833 75.9576 9.32566 73.0009C6.36799 70.0443 4.02211 66.5344 2.422 62.6716C0.821883 58.8088 -0.00112009 54.6688 1.14411e-06 50.488H15.8525C15.8491 52.7566 16.2933 55.0037 17.1597 57.1006C18.0261 59.1974 19.2976 61.1031 20.9015 62.7084C22.5055 64.3137 24.4104 65.5873 26.5073 66.4562C28.6042 67.3252 30.852 67.7724 33.122 67.7724Z"
        fillRule="evenodd"
      />
      <path
        d="M95.021 67.7723H79.2112C79.2094 63.4295 77.5695 59.247 74.6186 56.0592C71.6677 52.8713 67.6226 50.9124 63.2904 50.5732C63.1094 46.5138 61.3703 42.6802 58.4346 39.8688C55.4989 37.0575 51.5922 35.4846 47.5262 35.4769C43.4603 35.4692 39.5477 37.0273 36.6014 39.8276C33.655 42.6278 31.9014 46.4548 31.705 50.5135H15.8525C16.2491 43.5028 18.9659 36.8227 23.5757 31.5235C28.1855 26.2243 34.427 22.6065 41.3186 21.239C48.2103 19.8715 55.3613 20.8318 61.6473 23.969C67.9332 27.1062 72.9976 32.2422 76.0441 38.5697C81.696 41.0891 86.4962 45.1913 89.8641 50.38C93.232 55.5688 95.0232 61.6217 95.021 67.8064V67.7723Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
