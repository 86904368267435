import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCheckedIsLoggedIn } from "redux/user/selectors/selectCheckedIsLoggedIn";
import { selectUserProfile } from "redux/user/selectors/selectUserProfile";
import { checkIsLoggedIn } from "redux/user/userActions";
import { RouteEnums } from "router/routeEnums";
import { Spinner } from "shared/src/components/Spinner/Spinner";
import styles from "./auth.module.scss";

export const Auth: React.FC = ({ children }) => {
  const userProfile = useSelector(selectUserProfile);
  const checkedIsLoggedIn = useSelector(selectCheckedIsLoggedIn);
  const suspending = !checkedIsLoggedIn;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Loader = () => (
    <div className={styles.loader}>
      <Spinner />
    </div>
  );

  useEffect(() => {
    dispatch(checkIsLoggedIn());
  }, []);

  useEffect(() => {
    if (checkedIsLoggedIn && !userProfile) navigate(RouteEnums.login);
  }, [userProfile, checkedIsLoggedIn]);

  return suspending ? <Loader /> : <>{children}</>;
};
