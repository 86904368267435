import * as yup from "yup";

export const conditionalField = <T extends yup.AnySchema>(
  fieldSchema: T,
  conditionFieldName: string,
  compareWithTrue = true,
  requiredMessage?: string
) => {
  return fieldSchema.when(conditionFieldName, (condition) => {
    if (condition === compareWithTrue) return fieldSchema.required(requiredMessage);
    return fieldSchema.optional();
  });
};
