import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetUsersQuery, GetUsersResponseStats, GetUserStoredFilesResponse, UserAdminPage } from "api/UsersPageApi";
import { AccountFilters } from "models/AccountFilters";
import { MinimalCompany } from "models/MinimalCompany";
import { UserInfo2 } from "models/UserInfo";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { updateByKey } from "shared/src/helpers/utils";
import { BillingHistoryTO } from "shared/src/models/BillingHistory";
import { defaultPaginationInfo, Pagination, PaginationInfo } from "shared/src/models/Pagination";
import { StripeCard } from "shared/src/models/StripeCard";
import { Ticket } from "shared/src/models/TicketDetails";
import { UserPlanSnapshotDto } from "shared/src/models/UserPlanSnapshotDto";

export type LodableValue<T> = {
  loading: boolean;
  value?: T;
};

const toLodable = <T>(value?: T) => ({
  loading: false,
  value,
});

type UserPageInfoShape = {
  info: LodableValue<UserInfo2>;
  storedFilesInfo: LodableValue<GetUserStoredFilesResponse>;
  tickets: LodableValue<Ticket[]>;
  cards: LodableValue<StripeCard[]>;
  billingHistory: LodableValue<BillingHistoryTO>;
  userPlanSnapshot: LodableValue<Pagination<UserPlanSnapshotDto>>;
};

export type UserPageState = {
  users: UserAdminPage[];
  pagination: PaginationInfo;
  stats: GetUsersResponseStats;
  loading: boolean;
  querySort: QuerySort;
  search: string;
  accountFilters: AccountFilters[];
  group: GetUsersQuery["group"];
  btnLoading: boolean;
  userPlanSnapshot?: Pagination<UserPlanSnapshotDto>;
  allMinCompanies: MinimalCompany[];
  userPageInfo: UserPageInfoShape;
};

const defaultUserPageInfoState = {
  info: toLodable<UserInfo2>(),
  storedFilesInfo: toLodable<GetUserStoredFilesResponse>(),
  tickets: toLodable<Ticket[]>(),
  cards: toLodable<StripeCard[]>(),
  billingHistory: toLodable<BillingHistoryTO>(),
  userPlanSnapshot: toLodable<Pagination<UserPlanSnapshotDto>>(),
};

const initialState: UserPageState = {
  users: [],
  stats: {} as GetUsersResponseStats,
  pagination: defaultPaginationInfo,
  loading: false,
  querySort: {},
  accountFilters: [],
  search: "",
  group: "all",
  btnLoading: false,
  userPlanSnapshot: undefined,
  allMinCompanies: [],
  userPageInfo: defaultUserPageInfoState,
};

const userPageSlice = createSlice({
  name: "userPage",
  initialState,
  reducers: {
    setLoadingUserPageAction: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setUserPageResponseAction: (
      state,
      action: PayloadAction<Pick<UserPageState, "users" | "pagination" | "stats">>
    ) => {
      const { users, pagination, stats } = action.payload;
      return { ...state, users, pagination, stats, loading: false };
    },
    setQuerySortAction: (state, action: PayloadAction<QuerySort>) => ({ ...state, querySort: action.payload }),
    setSearchAction: (state, action: PayloadAction<string>) => ({ ...state, search: action.payload }),
    setUsersStatusGroupAction: (state, action: PayloadAction<GetUsersQuery["group"]>) => ({
      ...state,
      group: action.payload,
    }),
    setBtnLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      btnLoading: action.payload,
    }),
    setAccountFilters: (state, action: PayloadAction<AccountFilters[]>) => ({
      ...state,
      accountFilters: action.payload,
    }),
    setUserKyc: (state, action: PayloadAction<{ id: string; kyc: boolean }>) => {
      const { id, kyc } = action.payload;
      const users = updateByKey(state.users, "id", { id, isCanShare: kyc });
      return { ...state, users };
    },
    setAllMinCompanies: (state, action: PayloadAction<MinimalCompany[]>) => ({
      ...state,
      allMinCompanies: action.payload,
    }),
    // user page actions
    setLoadingUserPageState: (state, action: PayloadAction<{ key: keyof UserPageInfoShape; loading: boolean }>) => ({
      ...state,
      [action.payload.key]: {
        value: undefined,
        loading: action.payload.loading,
      },
    }),
    setUserPageInfo: (state, action: PayloadAction<UserInfo2>) => ({
      ...state,
      userPageInfo: { ...state.userPageInfo, info: { value: action.payload, loading: false } },
    }),
    setUserPageStoredFiles: (state, action: PayloadAction<GetUserStoredFilesResponse>) => ({
      ...state,
      userPageInfo: { ...state.userPageInfo, storedFilesInfo: { value: action.payload, loading: false } },
    }),
    setUserPageTickets: (state, action: PayloadAction<Ticket[]>) => ({
      ...state,
      userPageInfo: { ...state.userPageInfo, tickets: { value: action.payload, loading: false } },
    }),
    setUserPageCards: (state, action: PayloadAction<StripeCard[]>) => ({
      ...state,
      userPageInfo: { ...state.userPageInfo, cards: { value: action.payload, loading: false } },
    }),
    setUserPageBillingHistory: (state, action: PayloadAction<BillingHistoryTO>) => ({
      ...state,
      userPageInfo: { ...state.userPageInfo, billingHistory: { value: action.payload, loading: false } },
    }),
    setUserPlanSnapshot: (state, action: PayloadAction<Pagination<UserPlanSnapshotDto> | undefined>) => ({
      ...state,
      userPageInfo: { ...state.userPageInfo, userPlanSnapshot: { value: action.payload, loading: false } },
    }),
    clearCacheUserPage: (state) => ({
      ...state,
      userPageInfo: defaultUserPageInfoState,
    }),
  },
});

export const {
  setLoadingUserPageAction,
  setUserPageResponseAction,
  setQuerySortAction,
  setSearchAction,
  setAccountFilters,
  setUsersStatusGroupAction,
  setBtnLoading,
  setUserKyc,
  setAllMinCompanies,

  // user page actions exports
  setUserPlanSnapshot,
  setLoadingUserPageState,
  setUserPageInfo,
  setUserPageStoredFiles,
  setUserPageTickets,
  setUserPageCards,
  setUserPageBillingHistory,
  clearCacheUserPage,
} = userPageSlice.actions;
export const userPageReducer = userPageSlice.reducer;
