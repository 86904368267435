import { createSelector } from "reselect";
import { selectUsersState } from "./selectUsersState";

const selectUserPageInfoShape = createSelector([selectUsersState], (state) => state.userPageInfo);

export const selectUserPageUserInfo = createSelector([selectUserPageInfoShape], (state) => {
  const { value, loading } = state.info;
  return [loading, value] as const;
});
export const selectUserStoredFiles = createSelector([selectUserPageInfoShape], (state) => {
  const { value, loading } = state.storedFilesInfo;
  return [loading, value] as const;
});
export const selectUserTickets = createSelector([selectUserPageInfoShape], (state) => {
  const { value, loading } = state.tickets;
  return [loading, value] as const;
});
export const selectUserPageCards = createSelector([selectUserPageInfoShape], (state) => {
  const { value, loading } = state.cards;
  return [loading, value] as const;
});
export const selectUserPageBillingHistory = createSelector([selectUserPageInfoShape], (state) => {
  const { value, loading } = state.billingHistory;
  return [loading, value] as const;
});

export const selectUserPageUserPlanSnapshot = createSelector([selectUserPageInfoShape], (state) => {
  const { value, loading } = state.userPlanSnapshot;
  return [loading, value] as const;
});
