import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomTariffDto } from "api/CustomTariffsApi";
import { SimpleUser } from "models/User";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { defaultPaginationInfo, PaginationInfo } from "shared/src/models/Pagination";

export type CustomTariffsPageState = {
  tariffs: CustomTariffDto[];
  simpleUsers: SimpleUser[];
  pagination: PaginationInfo;
  loading: boolean;
  querySort: QuerySort;
  loadingBtnAction: boolean;
};

const initialState: CustomTariffsPageState = {
  tariffs: [],
  simpleUsers: [],
  pagination: defaultPaginationInfo,
  loading: false,
  querySort: {},
  loadingBtnAction: false,
};

const customTariffsPageSlice = createSlice({
  name: "ustomTariffsPage",
  initialState,
  reducers: {
    setLoadingCustomTariffsPageAction: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setLoadingBtnAction: (state, action: PayloadAction<boolean>) => {
      return { ...state, loadingBtnAction: action.payload };
    },
    setCustomTariffsPageRepAction: (
      state,
      action: PayloadAction<Pick<CustomTariffsPageState, "tariffs" | "pagination">>
    ) => {
      const { tariffs, pagination } = action.payload;
      return { ...state, tariffs, pagination, loading: false };
    },
    setQuerySortAction: (state, action: PayloadAction<QuerySort>) => ({ ...state, querySort: action.payload }),
    setSimpleUsers: (state, action: PayloadAction<SimpleUser[]>) => ({ ...state, simpleUsers: action.payload }),
  },
});

export const {
  setLoadingCustomTariffsPageAction,
  setCustomTariffsPageRepAction,
  setQuerySortAction,
  setSimpleUsers,
  setLoadingBtnAction,
} = customTariffsPageSlice.actions;
export const customTariffsReducer = customTariffsPageSlice.reducer;
