import { CreditCard, GoogleDocs, LogOut, TestTube, User, ViewColumns2 } from "iconoir-react";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { RouteEnums } from "router/routeEnums";
import { logOut } from "../../redux/user/userActions";
import styles from "./navigationSideBar.module.scss";

export const NavigationSideBar: React.FC = () => {
  const dispatch = useDispatch();
  const logOutHandler = () => {
    dispatch(logOut());
  };

  return (
    <div className={styles.navigationSideBar}>
      <div className={styles.navigationListWrapper}>
        <p className={styles.navigationListTitle}>Admin panel</p>
        <ul className={styles.navigationList}>
          <li className={styles.navigationLink}>
            <NavLink className={({ isActive }) => (isActive ? styles.activeLink : "")} to="/">
              <GoogleDocs className={styles.navigationIcon} />
              Statistics
            </NavLink>
          </li>
          <li className={styles.navigationLink}>
            <NavLink className={({ isActive }) => (isActive ? styles.activeLink : "")} to={RouteEnums.users}>
              <User className={styles.navigationIcon} />
              Users
            </NavLink>
          </li>
          <li className={styles.navigationLink}>
            <NavLink className={({ isActive }) => (isActive ? styles.activeLink : "")} to={RouteEnums.customTariffs}>
              <CreditCard className={styles.navigationIcon} />
              Custom tariffs
            </NavLink>
          </li>
          <li className={styles.navigationLink}>
            <NavLink className={({ isActive }) => (isActive ? styles.activeLink : "")} to={RouteEnums.channels}>
              <ViewColumns2 className={styles.navigationIcon} />
              Channels
            </NavLink>
          </li>
          <li className={styles.navigationLink}>
            <NavLink className={({ isActive }) => (isActive ? styles.activeLink : "")} to={RouteEnums.cronTests}>
              <TestTube className={styles.navigationIcon} />
              Cron tests
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.navigationLink}>
        <button onClick={logOutHandler} className={styles.activeLink}>
          <LogOut className={styles.navigationIcon} />
          Logout
        </button>
      </div>
    </div>
  );
};
