import { PayloadAction } from "@reduxjs/toolkit";
import { authClient } from "helpers/HttpClient";
import { LoginData } from "models/LoginData";
import { User } from "models/User";
import { call, put } from "redux-saga/effects";
import { setUser } from "redux/user/userSlice";
import { setToken } from "shared/src/helpers/token";

export function* loginSaga({ payload }: PayloadAction<LoginData>) {
  try {
    const loginResult: User = yield authClient.apiPost<LoginData>({ url: "/auth/login", data: payload });
    yield call(setToken, loginResult.accessToken);
    yield put(setUser(loginResult.user));
  } catch (e) {
    console.error(e);
  }
}
