import { yupResolver } from "@hookform/resolvers/yup";
import { LoginData } from "models/LoginData";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCheckedIsLoggedIn } from "redux/user/selectors/selectCheckedIsLoggedIn";
import { selectUserProfile } from "redux/user/selectors/selectUserProfile";
import { login } from "redux/user/userActions";
import { ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { Form } from "shared/src/components/Form/Form";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { PetaboxLogo } from "shared/src/images/PetaboxLogo";
import * as yup from "yup";
import styles from "./login.module.scss";

const YupSchemaPassword = yup.object({
  password: yup.string().required("Password is a required field"),
  email: yup.string().required("Email is a required field"),
});

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm<LoginData>({
    mode: "onChange",
    resolver: yupResolver(YupSchemaPassword),
  });

  const userProfile = useSelector(selectUserProfile);
  const checkedIsLoggedIn = useSelector(selectCheckedIsLoggedIn);
  const navigate = useNavigate();

  const onSubmit = (formData: LoginData) => {
    dispatch(
      login({
        email: formData.email,
        password: formData.password,
      })
    );
  };

  useEffect(() => {
    if (userProfile && checkedIsLoggedIn) navigate("/");
  }, [userProfile, checkedIsLoggedIn]);

  return (
    <div className={styles.loginContainer}>
      <PetaboxLogo className={styles.logo} fill="white" height={24} />
      <Form onSubmit={handleSubmit(onSubmit)} title="Sign in to your account">
        <ControlledText className={styles.field} placeholder="Email" control={control} name="email" type="email" />
        <ControlledText
          className={styles.field}
          placeholder="Password"
          control={control}
          name="password"
          type="password"
        />
        <PbButton size="lg" fullwidth type="submit">
          Sign In
        </PbButton>
      </Form>
      <div className={styles.footer}>Petabox © 2022. All Rights Reserved.</div>
    </div>
  );
};
