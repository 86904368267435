import { AddNewTariff } from "components/AddNewTariff/AddNewTariff";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomTariffsAction } from "redux/customTariffsPage/customTariffsPageActions";
import { setQuerySortAction } from "redux/customTariffsPage/customTariffsPageSlice";
import { selectCustomTariffsPageState } from "redux/customTariffsPage/selectors/selectCustomTariffsState";
import { CommonTable, QuerySort, TableItem } from "shared/src/components/CommonTable/CommonTable";
import { Heading } from "shared/src/components/Heading/Heading";
import { PageSwitch } from "shared/src/components/PageSwitch/PageSwitch";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { PerPageSwitcher } from "shared/src/components/PerPageSwitcher/PerPageSwitcher";
import { centsToUsd } from "shared/src/helpers/utils";
import styles from "./styles.module.scss";

const CustomTariffs: React.FC = () => {
  const customTariffsState = useSelector(selectCustomTariffsPageState);
  const [addNewTariffOpen, setAddNewTariffOpen] = useState(false);

  const { tariffs, loading, pagination, querySort } = customTariffsState;

  const items: TableItem<typeof tariffs[0]>[] = [
    { label: "Tariff Name", render: (x) => <span>{x.name}</span> },
    { label: "Users", render: (x) => <span>{x.users}</span> },
    {
      label: "Storage / Trial",
      render: (x) => (
        <span>
          {x.storageSizePretty} / {x.trial_storage_limit ?? 0} TB
        </span>
      ),
      sortKey: "storageSize",
    },
    {
      label: "Transfer / Trial",
      render: (x) => (
        <span>
          {x.bandwidthPretty} / {x.trial_transfer_limit ?? 0} TB
        </span>
      ),
      sortKey: "bandwidth",
    },

    {
      label: "Additional Storage, $ / GB",
      render: (x) => <span>{x.cost_storage_gb}</span>,
      sortKey: "costStorageGb",
    },
    {
      label: "Additional Transfer, $ / GB",
      render: (x) => <span>{x.cost_bandwidth_gb}</span>,
      sortKey: "costBandwidthGb",
    },
    { label: "Price", render: (x) => <span>{centsToUsd(x.price)}</span>, sortKey: "price" },
    // { label: "Trial, Days", render: (x) => <span>{x.trialDays}</span> },
  ];

  const dispatch = useDispatch();
  const getTariffs = (...args: Parameters<typeof getCustomTariffsAction>) => dispatch(getCustomTariffsAction(...args));
  const setQuerySort = (querySort: QuerySort) => dispatch(setQuerySortAction(querySort));

  const Pagination = () => {
    return (
      <div className={styles.pagination}>
        <PerPageSwitcher value={pagination.perPage} onChange={(x) => getTariffs({ pagination: { perPage: x } })} />
        <PageSwitch onChange={(page) => getTariffs({ pagination: { page } })} pageInfo={pagination} />
      </div>
    );
  };

  useEffect(() => {
    getTariffs({ querySort });
  }, [querySort]);

  return (
    <div className={styles.content}>
      <div className="page">
        <header className={styles.header}>
          <Heading variant="h2">Tariffs</Heading>
          <PbButton onClick={() => setAddNewTariffOpen(true)} size="lg">
            Create tariff
          </PbButton>
        </header>

        <main className={styles.main}>
          <Pagination />
          <CommonTable
            loading={loading}
            onSortChange={setQuerySort}
            sortInfo={querySort}
            items={items}
            values={tariffs}
            idKey="id"
          />
          <Pagination />
        </main>
        {addNewTariffOpen && <AddNewTariff open={addNewTariffOpen} onClose={() => setAddNewTariffOpen(false)} />}
      </div>
    </div>
  );
};

export default CustomTariffs;
