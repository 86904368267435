import { dashClient } from "helpers/HttpClient";
import { CreateTariff, TariffDto } from "models/TariffDto";
import { SimpleUser } from "models/User";
import { BaseRestClient, withParams } from "shared/src/http";
import { Pagination, PaginationQueryParams } from "shared/src/models/Pagination";
import { SortParams } from "shared/src/models/SortParams";

export type CustomTariffDto = TariffDto & {
  users: number;
};

export type GetCustomTariffsQuery = PaginationQueryParams & SortParams;
export type GetCustomTarfiffsRep = Pagination<CustomTariffDto>;

export class CustomTariffsApi {
  dashClient: BaseRestClient;

  constructor() {
    this.dashClient = dashClient;
  }

  async getAll(query = {} as GetCustomTariffsQuery) {
    const url = withParams("/admin/custom-tariffs", query);
    const rep = await this.dashClient.apiGet({ url });
    return rep;
  }

  async createTariff(data = {} as CreateTariff) {
    const rep = await this.dashClient.apiPost({ url: "/admin/custom-tariffs", data });
    return rep;
  }

  async getSimpleUsersList(): Promise<SimpleUser[]> {
    const rep: SimpleUser[] = await this.dashClient.apiGet({ url: "/admin/users/simplified-list" });
    return rep;
  }
}

export const customTariffsApi = new CustomTariffsApi();
