import { PayloadAction } from "@reduxjs/toolkit";
import { customTariffsApi, GetCustomTarfiffsRep } from "api/CustomTariffsApi";
import { get } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { getPaginationFromDto } from "shared/src/models/Pagination";
import { GetCustomTariffsActionPayload } from "../customTariffsPageActions";
import { setCustomTariffsPageRepAction, setLoadingCustomTariffsPageAction } from "../customTariffsPageSlice";
import { selectCustomTariffsPageState } from "../selectors/selectCustomTariffsState";

export function* getCustomTariffsSaga(action: PayloadAction<GetCustomTariffsActionPayload>) {
  const {
    pagination: { perPage, page },
    querySort,
  } = yield select(selectCustomTariffsPageState);
  const pagePayload = get(action, "payload.pagination.page");
  const perPagePayload = get(action, "payload.pagination.perPage");

  try {
    yield put(setLoadingCustomTariffsPageAction(true));

    const rep: GetCustomTarfiffsRep = yield call([customTariffsApi, customTariffsApi.getAll], {
      page: pagePayload ?? page,
      perPage: perPagePayload ?? perPage,
      order: querySort?.ord,
      orderBy: querySort?.ordBy,
    });

    const { items } = rep;
    const pagination = getPaginationFromDto(rep);

    yield put(
      setCustomTariffsPageRepAction({
        tariffs: items,
        pagination,
      })
    );
  } catch (e) {
    yield put(setLoadingCustomTariffsPageAction(false));
  }
}
