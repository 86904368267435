import { RangeDatePicker } from "components/RangeDatePicker/RangeDatePicker";
import { addDays } from "date-fns";
import { Refresh } from "iconoir-react";
import { safeDateFormat } from "pages/UsersPage/UsersPage";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUsersForTestCronAction,
  getUsersForTestCronAction,
  initUsersForTestCronAction,
  triggerCronAction,
} from "redux/cronTests/cronTestsActions";
import { selectUsersForTestsCrone } from "redux/cronTests/selectors/selectTestCronState";
import { CommonTable, TableItem } from "shared/src/components/CommonTable/CommonTable";
import { Heading } from "shared/src/components/Heading/Heading";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import styles from "./cronTests.module.scss";

const now = new Date();
export const CronTests: React.FC = () => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector(selectUsersForTestsCrone);
  const defaultSelected: DateRange = {
    from: now,
    to: addDays(now, 4),
  };
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

  const items: TableItem<typeof users[0]>[] = [
    { label: "ID", render: ({ id }) => id },
    {
      label: "User Name",
      sortKey: "name",
      render: ({ name, id, plan }) => name,
    },
    {
      label: "Email",
      sortKey: "email",
      render: ({ email }) => email,
    },
    {
      label: "Status",
      render: ({ status }) => <span className={styles[status]}>{status}</span>,
    },
    {
      label: "Plan",
      render: ({ userPlan }) => userPlan?.name ?? "n/a",
    },
    {
      label: "Registration",
      sortKey: "createdAt",
      render: ({ createdAt }) => safeDateFormat(createdAt),
    },

    {
      label: "Plan Start",
      render: ({ userPlan }) => (userPlan?.created_at ? safeDateFormat(userPlan.created_at) : "N/A"),
    },
    {
      label: "Plan End",
      render: ({ userPlan }) => (userPlan?.end_at ? safeDateFormat(userPlan.end_at) : "N/A"),
    },
  ];

  const userAction = () => dispatch(users.length > 0 ? clearUsersForTestCronAction() : initUsersForTestCronAction());

  useEffect(() => {
    dispatch(getUsersForTestCronAction());
  }, []);

  const trigger = () => {
    if (range?.from && range?.to) {
      dispatch(
        triggerCronAction({
          start_at: range?.from,
          end_at: range?.to,
        })
      );
    }
  };
  return (
    <div className={styles.content}>
      <div className="page">
        <header className={styles.header}>
          <Heading variant="h2">Cron tests</Heading>
          <div className={styles.rightActions}>
            <PbButton onClick={() => dispatch(getUsersForTestCronAction())} loading={loading} size="lg" color="success">
              <Refresh width={20} height={20} color="#ffffff" />
            </PbButton>
            <PbButton loading={loading} onClick={userAction} size="lg">
              {users.length > 0 ? "Clear" : "Init"} test users
            </PbButton>
          </div>
        </header>
        <main className={styles.main}>
          <div className={styles.controlContainer}>
            <RangeDatePicker range={range} setRange={setRange} />
            <PbButton loading={loading} onClick={trigger} size="lg">
              Exec cron
            </PbButton>
          </div>
          <CommonTable loading={loading} items={items} values={users} idKey="id" />
        </main>
      </div>
    </div>
  );
};
