import { createAction } from "@reduxjs/toolkit";
import {
  ChannelsTypeList,
  CreatePartner,
  CreateSupervisor,
  PartnerEditForm,
  SupervisorEditForm,
} from "models/Channels";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { PaginationChangePayload } from "shared/src/models/Pagination";

export type GetSupervisorsOrPartnersPayload = {
  pagination?: PaginationChangePayload;
  querySort?: QuerySort;
  search?: string;
  type: ChannelsTypeList;
};
export const getSupervisorsOrPartnersList =
  createAction<GetSupervisorsOrPartnersPayload>("getSupervisorsOrPartnersList");
export const getAllSupervisors = createAction("getAllSupervisors");
export const createSupervisor = createAction<CreateSupervisor>("createSupervisor");
export const createPartner = createAction<CreatePartner>("createPartner");
export const editSupervisor = createAction<SupervisorEditForm>("editSupervisor");
export const editPartner = createAction<PartnerEditForm>("editPartner");
export const reGenerateMasterKey = createAction<{ id: string; type: ChannelsTypeList }>("reGenerateMasterKey");
