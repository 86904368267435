import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { makeStore } from "redux/store";
import { App } from "./App";

const store = makeStore();
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);
