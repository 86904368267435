import React, { useMemo, useRef } from "react";
import style from "./dropdownVertical.module.scss";
import clsx from "clsx";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import { MoreVert } from "iconoir-react";

export interface DropItem {
  icon?: React.ReactNode;
  text: JSX.Element | string | number | null;
  isAccent?: boolean;
  onClick?: () => void;
  section: 1 | 2;
  keepOpenedAfterClick?: boolean;
}

interface IProps {
  dropdowns: DropItem[];
  isDeleteMarket?: boolean;
  noPaddingIcon?: boolean;
}

const DELETE_MARKER_ACTION = ["Delete", "Rename"];

const DropdownVertical: React.FC<IProps> = ({ dropdowns, isDeleteMarket, noPaddingIcon }) => {
  const dropRef = useRef<HTMLDivElement>(null);
  const dropPanelRef = useRef<HTMLDivElement>(null);

  const dropdownList = useMemo(() => {
    return (
      <div className={clsx(style.dropdown, style.bottomPosition)} ref={dropPanelRef}>
        <div className={style.section}>
          {dropdowns.map((item, i, arr) => {
            if (i > 0 && item.section !== arr[i - 1].section) {
              return isDeleteMarket && !DELETE_MARKER_ACTION.includes(item.text as string) ? null : (
                <React.Fragment key={i}>
                  <div className={style.border} />
                  <div className={clsx(style.item)} onClick={item.onClick}>
                    {item.icon && <div className={style.icon}>{item.icon}</div>}
                    <div className={clsx(style.text, item.isAccent && style.textAccent)}>{item.text}</div>
                  </div>
                </React.Fragment>
              );
            }
            return isDeleteMarket && !DELETE_MARKER_ACTION.includes(item.text as string) ? null : (
              <div key={i} className={clsx(style.item)} onClick={item.onClick}>
                {item.icon && <div className={style.icon}>{item.icon}</div>}
                <div className={clsx(style.text, item.isAccent && style.textAccent)}>{item.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [dropdowns]);

  return (
    <div className={style.container}>
      <div className={style.iconMain} ref={dropRef}>
        <div className={style.iconWrapper}>
          <Dropdown trigger={["click"]} overlay={dropdownList} animation="slide-up">
            <button className={clsx(style.iconWrapperButton, noPaddingIcon && style.iconWrapperNoPadding)}>
              <MoreVert color="#5A5D65" width={24} height={24} />
            </button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default DropdownVertical;
