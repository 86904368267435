import clsx from "clsx";
import React, { useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { SvgArrowDropdown } from "../icons/ArrowDownSvg";
import styles from "./PerPageSwitcher.module.scss";

type PagePageRowSwitcherProps = {
  value?: number;
  onChange?: (value: number) => void;
  className?: string;
};

export const PerPageSwitcher: React.FC<PagePageRowSwitcherProps> = (props) => {
  const { value, onChange, className } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const options = useMemo(() => [15, 25, 50, 100].map((x) => ({ label: x.toString(), value: x })), []);
  const [position, setPosition] = useState({ top: "auto", bottom: "auto", ready: false });

  const toggle = () => {
    if (!open) {
      const $head = ref.current;
      const listHeight = listRef.current?.clientHeight ?? 0;
      const bottom = $head?.getBoundingClientRect()?.bottom ?? 0;
      const screenY = window.innerHeight;
      const renderBottom = screenY >= bottom + listHeight;
      const bottomCase = { top: "100%", bottom: "auto", ready: true };
      const topCase = { bottom: "100%", top: "auto", ready: true };
      setPosition(renderBottom ? bottomCase : topCase);
    }
    setOpen((x) => !x);
  };

  useOnClickOutside([ref], () => setOpen(false));

  return (
    <div className={clsx(styles.root, className)}>
      <span className={styles.hint}>Rows per page:</span>

      <div onClick={toggle} ref={ref} className={styles.wrapper}>
        <input className={styles.input} value={value} readOnly={true} />
        <div className={styles.arrowIcon}>
          <SvgArrowDropdown />
        </div>

        <div
          style={{
            top: position.top,
            bottom: position.bottom,
            opacity: position.ready ? 100 : 0,
            visibility: open ? "visible" : "hidden",
            position: open ? "absolute" : "fixed",
          }}
          className={styles.list}
          ref={listRef}
        >
          {options.map((el, key) => {
            const isActive = false;

            return (
              <div
                key={key}
                className={clsx(styles.listItemElement, isActive && styles.listItemElementActive)}
                tabIndex={-1}
                onClick={() => onChange?.(el.value)}
              >
                {el.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
