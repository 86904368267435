import { PayloadAction } from "@reduxjs/toolkit";
import { usersPageApi } from "api/UsersPageApi";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";
import { ExtendUserTrial, getUsersAction, getUserTariffsHistory } from "../userPageActions";
import { setBtnLoading } from "../userPageSlice";

export function* extendUserTrialSaga({ payload }: PayloadAction<ExtendUserTrial>) {
  const { userId, days, onSuccess, actionPage } = payload;
  yield put(setBtnLoading(true));

  try {
    yield call([usersPageApi, usersPageApi.extendTrial], userId, days);
    onSuccess?.();
    yield put(actionPage === "table" ? getUsersAction() : getUserTariffsHistory({ id: userId, query: {} }));
    yield call([toast, toast.success], "Successfully extended trial");
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
  } finally {
    yield put(setBtnLoading(false));
  }
}
