import clsx from "clsx";
import { DeleteCircledOutline } from "iconoir-react";
import _ from "lodash";
import { nanoid } from "nanoid";
import React, { LegacyRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TextInput.module.scss";

type DefaultInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
  HTMLInputElement | HTMLTextAreaElement
>;
type TextInputProps = {
  label?: string;
  onChange?: (value: string) => void;
  error?: string;
  labelExtraContent?: JSX.Element;
  inputSign?: string;
  changeType?: () => void;
  icon?: React.ReactNode;
  typeState?: string;
  textArea?: boolean;
} & Omit<DefaultInputProps, "id" | "onChange">;

export const TextInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement | null, TextInputProps>(
  (prop, ref) => {
    const id = useRef(nanoid()).current;
    const { t } = useTranslation();
    const { className, label, changeType, icon, onChange, error, textArea, ...props } = prop;
    const { labelExtraContent, inputSign, type, typeState = "", required } = prop;

    return (
      <div className={clsx(styles.wrap, className)}>
        {label && (
          <label className={styles.label} htmlFor={id}>
            <span className={styles.labelText}>
              {label}
              {required && <span className={styles.redDot}>·</span>}
            </span>
            {labelExtraContent}
          </label>
        )}
        <div className={clsx(styles.inputWrapper, !!error && styles.inputError)}>
          {textArea ? (
            <textarea
              {..._.omit(props, ["typeState", "changeType", "inputSign", "labelExtraContent", "textArea"])}
              className={styles.input}
              onChange={({ target: { value } }) => onChange?.(value)}
              ref={ref as LegacyRef<HTMLTextAreaElement>}
              id={id}
            />
          ) : (
            <input
              {..._.omit(props, ["typeState", "changeType", "inputSign", "labelExtraContent", "textArea"])}
              className={styles.input}
              onChange={({ target: { value } }) => {
                const toNumber = (value?: string) => {
                  if (value === "") return "";
                  return Number(value) as unknown as string;
                };

                onChange?.(typeState === "number" ? toNumber(value) : value);
              }}
              ref={ref as LegacyRef<HTMLInputElement>}
              type={typeState}
              id={id}
            />
          )}
          {icon && type === "password" ? (
            <div>
              <div onClick={changeType} className={styles.wrapperVisible}>
                {icon}
              </div>
            </div>
          ) : (
            <div className={styles.wrapperVisible}>{icon}</div>
          )}
          {inputSign && <span className={styles.inputSign}>{inputSign}</span>}
        </div>
        {!!error && (
          <small className={styles.error}>
            <DeleteCircledOutline />
            <span className={styles.errorText}>{t(error)}</span>
          </small>
        )}
      </div>
    );
  }
);
