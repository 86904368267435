import { conditionalField } from "shared/src/helpers/conditionalFieldYup";
import * as yup from "yup";

export const newAccountFormSchema = yup.object().shape({
  isCorporateClient: yup.boolean().default(false),
  name: conditionalField(
    yup.string().required("This field is required").trim(),
    "isCorporateClient",
    false,
    "This field is required"
  ),
  email: yup.string().required("This field is required").trim().email("Please enter a valid email address"),
  tariffId: yup.number().nullable(true),
  firstName: conditionalField(
    yup.string().required("This field is required").trim(),
    "isCorporateClient",
    true,
    "This field is required"
  ),
  lastName: conditionalField(
    yup.string().required("This field is required").trim(),
    "isCorporateClient",
    true,
    "This field is required"
  ),
  companyName: conditionalField(yup.string().trim(), "isCorporateClient", true, "This field is required"),
  password: yup
    .string()
    .required("New password is a required field")
    .trim()
    .min(8, "New password must be at least 8 characters long")
    .matches(
      // eslint-disable-next-line
      /^(.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).*){8,}$/,
      "Password must contain at least 1 Capital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Match password")
    .trim(),
  country: conditionalField(yup.string().required(), "isCorporateClient", true, "This field is required"),
  dataVolume: conditionalField(yup.string().required(), "isCorporateClient", true, "This field is required"),
  phone: yup.string().default(undefined),
});
