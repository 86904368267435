import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUsersPageBtnLoading } from "redux/userPage/selectors/selectUsersState";
import { ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { CoreModal } from "shared/src/components/Modal/Modal";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { UserProfile } from "shared/src/models/UserModel";
import * as yup from "yup";
import styles from "./actionUserModals.module.scss";

type DeleteUserModalProps = {
  active: boolean;
  onClose: () => void;
  user: UserProfile;
  onSubmit: (reason?: string) => void;
};

export const DeleteUserModal: React.FC<DeleteUserModalProps> = (props) => {
  const { active = false, onClose, user, onSubmit } = props;
  const { control, trigger, getValues } = useForm<{ reason?: string }>({
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        reason: yup.string().trim(),
      })
    ),
    defaultValues: {
      reason: "",
    },
  });
  const loading = useSelector(selectUsersPageBtnLoading);

  const submit = async () => {
    if (!(await trigger())) return;
    const formData = getValues();

    onSubmit(formData.reason);
    onClose();
  };

  return (
    <CoreModal
      footer={
        <div className={styles.footer}>
          <PbButton onClick={onClose} color="secondary-bordered" className={styles.btn}>
            Cancel
          </PbButton>
          <PbButton onClick={submit} loading={loading} disabled={loading} color="danger" className={styles.btn}>
            Delete account
          </PbButton>
        </div>
      }
      className={styles.modal}
      title="Delete account"
      closeOutClick
      visible={active}
      onClose={onClose}
    >
      <p className={styles.text}>
        Are you sure you want to freeze{" "}
        <span className={styles.name}>
          {user.name} {user.lastName}
        </span>{" "}
        account?
      </p>
      <ControlledText
        textArea
        className={styles.field}
        label="Indicate the reason for deletion"
        control={control}
        name="reason"
        type="text"
      />
    </CoreModal>
  );
};
