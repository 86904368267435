import { yupResolver } from "@hookform/resolvers/yup";
import { newAccountFormSchema } from "components/AddNewAccount/newAccountFormSchema";
import { CorporateAccountFields, DefaultAccountFields } from "models/AddNewAccount";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useDataVolumeOptions } from "redux/catalogs/selectors/selectDataVolumesCatalog";
import { useGetRelatedCatalog } from "redux/catalogs/selectors/selectTariffCatalog";
import { createNewUserAction } from "redux/userPage/userPageActions";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import { ControlledSelect, ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { RightDrawer } from "shared/src/components/RightDrawer/RightDrawer";
import { countriesList } from "shared/src/data/countries";
import { SubPlanEnum } from "shared/src/models/SubPlan";
import styles from "./addNewAccount.module.scss";

export type CreateUserFormType = {
  confirmPassword: string;
  isCorporateClient: boolean;
} & DefaultAccountFields &
  CorporateAccountFields;

type AddNewAccountProps = {
  open: boolean;
  onClose: () => void;
};

export const AddNewAccount: React.FC<AddNewAccountProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, trigger, getValues, watch, setValue, resetField } = useForm<CreateUserFormType>({
    mode: "onChange",
    resolver: yupResolver(newAccountFormSchema),
  });
  const isCorporateClient = watch("isCorporateClient");
  const relatedTariffs = useGetRelatedCatalog(isCorporateClient);
  const tariffOptions = useMemo(() => {
    return relatedTariffs.map((x) => ({ value: x.id, label: x.name }));
  }, [relatedTariffs]);

  const dataVolumeOptions = useDataVolumeOptions();
  const setDefaultState = () => {
    resetField("country");
    resetField("dataVolume");
    setValue("name", "");
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("companyName", "");
    setValue("email", "");
    setValue("phone", undefined);
    setValue("password", "");
    setValue("confirmPassword", "");
    setValue("tariffId", isCorporateClient ? SubPlanEnum["Corporate"] : SubPlanEnum["Pay As You Go"]);
  };

  const onSuccess = () => {
    onClose();
    setDefaultState();
  };

  const onCreate = async () => {
    if (!(await trigger())) {
      return;
    }
    const formData = getValues();
    dispatch(
      createNewUserAction({
        ...formData,
        phone: formData.phone ?? undefined,
        onSuccess,
        tariffId: formData.tariffId,
      })
    );
  };

  useEffect(() => {
    setDefaultState();
  }, [isCorporateClient]);

  return (
    <RightDrawer
      footer={
        <div className={styles.actions}>
          <PbButton onClick={onClose} className={styles.action} size="md" color="secondary">
            Cancel
          </PbButton>
          <PbButton onClick={handleSubmit(onCreate)} type="submit" className={styles.action} size="md" color="primary">
            Create
          </PbButton>
        </div>
      }
      open={open}
      onClose={onClose}
      title="Add new account"
    >
      <form onSubmit={handleSubmit(onCreate)}>
        <div className={styles.check}>
          <Checkbox
            checked={isCorporateClient}
            onChange={() => setValue("isCorporateClient", !isCorporateClient)}
            name="isCorporateClient"
            id="isCorporateClient"
            label="Corporate client"
          />
        </div>
        {isCorporateClient ? (
          <>
            <div className={styles.grid2fr}>
              <ControlledText
                required
                className={styles.field}
                label="First name"
                control={control}
                name="firstName"
                type="text"
              />
              <ControlledText
                required
                className={styles.field}
                label="Last name"
                control={control}
                name="lastName"
                type="text"
              />
            </div>
            <div className={styles.grid2fr}>
              <ControlledText
                required
                className={styles.field}
                label="Company name"
                control={control}
                name="companyName"
                type="text"
              />
              <ControlledText
                required
                className={styles.field}
                label="Work Email"
                control={control}
                name="email"
                type="text"
              />
            </div>
            <ControlledSelect
              className={styles.field}
              options={tariffOptions}
              label="Tariff Plan"
              required
              control={control}
              name="tariffId"
            />
            <ControlledSelect
              className={styles.field}
              options={countriesList}
              label="Country"
              placeholder="Choose a country"
              name="country"
              control={control}
              required
            />
            <ControlledText className={styles.field} label="Phone" control={control} name="phone" type="text" />
            <ControlledSelect
              className={styles.field}
              options={dataVolumeOptions}
              label="Storage amount"
              placeholder="Storage amount"
              name="dataVolume"
              control={control}
              required
            />
          </>
        ) : (
          <>
            <ControlledSelect
              className={styles.field}
              options={tariffOptions}
              label="Tariff plan"
              placeholder="Choose tariff"
              name="tariffId"
              control={control}
              required
            />
            <ControlledText required className={styles.field} label="Name" control={control} name="name" type="text" />
            <ControlledText
              required
              className={styles.field}
              label="Email"
              control={control}
              name="email"
              type="text"
            />
          </>
        )}
        <ControlledText
          required
          className={styles.field}
          label="Password"
          control={control}
          name="password"
          type="password"
        />
        <ControlledText
          label="Confirm password"
          className={styles.field}
          control={control}
          name="confirmPassword"
          type="password"
          required
        />
      </form>
    </RightDrawer>
  );
};
