import { conditionalField } from "shared/src/helpers/conditionalFieldYup";
import * as yup from "yup";

export const newTariffFormSchema = yup.object().shape({
  isTrial: yup.boolean(),
  trialDays: conditionalField(
    yup.number().integer().min(1, "Must be biggest then 0"),
    "isTrial",
    true,
    "This field is required"
  ),
  name: yup.string().required("This field is required").trim(),
  storage_size: yup.number().required("This field is required"),
  bandwidth: yup.number().required("This field is required"),
  cost_storage_gb: yup.number().required("This field is required"),
  cost_bandwidth_gb: yup.number().required("This field is required"),
  trial_storage_limit: yup.number().required("This field is required"),
  trial_transfer_limit: yup.number().required("This field is required"),
  description: yup.string().required("This field is required").trim(),
  price: yup.number().required("This field is required"),
});
