import { UserAdminPage } from "api/UsersPageApi";
import { dashClient } from "helpers/HttpClient";
import { SetUserPlanDurationDto } from "models/SetUserPlanDuration";
import { BaseRestClient } from "shared/src/http";

export type UserForTestCronAdminPage = UserAdminPage & {
  userPlan: UserAdminPage["plan"];
};
export class CronTestsApi {
  dashClient: BaseRestClient;

  constructor() {
    this.dashClient = dashClient;
  }

  async getUsersForTestCron(): Promise<UserForTestCronAdminPage[]> {
    const rep = await this.dashClient.apiGet<UserForTestCronAdminPage[]>({ url: "/admin/test/get-test-custom-users" });
    return rep;
  }
  async initUsersForTestCron(): Promise<void> {
    const rep = await this.dashClient.apiPost<void>({ url: "/admin/test/init-test-custom-users" });
    return rep;
  }
  async clearUsersForTestCron(): Promise<void> {
    const rep = await this.dashClient.apiPost<void>({ url: "/admin/test/clear-test-custom-users" });
    return rep;
  }
  async setPlanDurationUsersForTestCron(data: SetUserPlanDurationDto): Promise<void> {
    const rep = await this.dashClient.apiPost<void>({ url: "/admin/test/set-plan-duration-custom-users", data });
    return rep;
  }
  async triggerCron(): Promise<void> {
    const rep = await this.dashClient.apiPost<void>({ url: "/admin/test/trigger-cron-custom-users" });
    return rep;
  }
}

export const cronTestsApi = new CronTestsApi();
