import clsx from "clsx";
import { format } from "date-fns";
import { CreateInvoice } from "features/CreateInvoice/CreateInvoice";
import { Download } from "iconoir-react";
import { CreateInvoiceDto } from "models/CreateInvoice.dto";
import { InvoiceType } from "models/InvoiceType";
import styles from "pages/UserInfo/userInfo.module.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserPageBillingHistory } from "redux/userPage/selectors/selectUserPage";
import { createInvoiceAction, getUserPageBillingHistory } from "redux/userPage/userPageActions";
import { CommonTable, TableItem } from "shared/src/components/CommonTable/CommonTable";
import { Heading } from "shared/src/components/Heading/Heading";
import { PageSwitch } from "shared/src/components/PageSwitch/PageSwitch";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { PerPageSwitcher } from "shared/src/components/PerPageSwitcher/PerPageSwitcher";
import { centsToUsd } from "shared/src/helpers/utils";
import { BillingHistoryStatusEnum, BillingHistoryTransactionType } from "shared/src/models/BillingHistory";

type BillingHistoryProps = {
  userId: string;
};

export const BillingHistory: React.FC<BillingHistoryProps> = ({ userId }) => {
  const [createInvoiceWindow, setCreateInvoiceWindow] = useState(false);
  const [pending, setPending] = useState(false);
  const [loading, billingHistory] = useSelector(selectUserPageBillingHistory);

  const dispatch = useDispatch();

  const onCreateInvoice = (data: CreateInvoiceDto) => {
    setPending(true);
    dispatch(
      createInvoiceAction({
        data,
        onSuccess: () => {
          setCreateInvoiceWindow(false);
          setPending(false);
          dispatch(getUserPageBillingHistory({ id: userId }));
        },
        userId: userId,
      })
    );
  };

  useEffect(() => {
    dispatch(getUserPageBillingHistory({ id: userId }));
  }, [userId]);

  const items: TableItem<BillingHistoryTransactionType>[] = [
    {
      label: "",
      render: (x) => (
        <span
          className={clsx(styles.statusText, {
            [styles.failed]: x.status === BillingHistoryStatusEnum.failed,
            [styles.pending]: x.status === BillingHistoryStatusEnum.pending,
            [styles.success]: x.status === BillingHistoryStatusEnum.success,
          })}
        >
          {x.status}
        </span>
      ),
    },
    {
      label: "Date",
      render: (x) => <>{format(new Date(x.createdAt), "P")}</>,
    },
    {
      label: "Invoice Number",
      render: (x) => x.invoiceId ?? "N/A",
    },
    {
      label: "Plan",
      render: (x) => <>{x?.userPlan?.tariff?.name}</>,
    },
    {
      label: "Amount",
      render: (x) => <div className={styles.tdAmount}>{centsToUsd(x.amount)}</div>,
    },
    {
      label: "Download",
      render: (x) => (
        <>
          {x.downloadPdfLink && (
            <a className={styles.pdf} href={x.downloadPdfLink} rel="noreferrer" target="_blank">
              <span>PDF</span>
              <Download color="#41A8ED" width={16} height={16} />
            </a>
          )}
        </>
      ),
      width: 100,
    },
  ];

  return (
    <div className={styles.billingHistory}>
      <div className={styles.billingHistoryHead}>
        <Heading variant="h2">Billing history</Heading>
        <PbButton onClick={() => setCreateInvoiceWindow(true)} size="lg" color="success">
          Create invoice
        </PbButton>
      </div>
      <CommonTable
        className={styles.billingHistoryTable}
        items={items}
        idKey="id"
        values={billingHistory?.items ?? []}
        loading={loading}
      />
      {billingHistory?.pagesCount && billingHistory.pagesCount > 1 && (
        <div className={styles.pagination}>
          <PerPageSwitcher
            value={billingHistory.perPage}
            onChange={(x) => dispatch(getUserPageBillingHistory({ id: userId, query: { perPage: x } }))}
          />
          <PageSwitch
            onChange={(page) => dispatch(getUserPageBillingHistory({ id: userId, query: { page } }))}
            pageInfo={{
              totalCount: billingHistory.totalCount,
              page: billingHistory.page ?? 1,
              perPage: billingHistory.perPage ?? 1,
              pagesCount: billingHistory.pagesCount,
            }}
          />
        </div>
      )}

      <CreateInvoice
        pending={pending}
        active={createInvoiceWindow}
        onClose={() => setCreateInvoiceWindow(false)}
        userId={userId}
        invoiceType={InvoiceType.user}
        onSubmit={onCreateInvoice}
      />
    </div>
  );
};
