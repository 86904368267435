import { takeLatest } from "redux-saga/effects";
import { createTariffsSaga } from "redux/customTariffsPage/sagas/createTariffsSaga";
import { getSimpleUserListSaga } from "redux/customTariffsPage/sagas/getSimpleUserListSaga";
import { createNewTariffAction, getCustomTariffsAction, getSimpleUserListAction } from "./customTariffsPageActions";
import { getCustomTariffsSaga } from "./sagas/getCustomTariffsSaga";

export function* customTariffsPageSaga() {
  yield takeLatest(getCustomTariffsAction.type, getCustomTariffsSaga);
  yield takeLatest(createNewTariffAction.type, createTariffsSaga);
  yield takeLatest(getSimpleUserListAction.type, getSimpleUserListSaga);
}
