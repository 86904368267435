import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import * as yup from "yup";
import Lazy from "yup/lib/Lazy";
import { AnyObject } from "yup/lib/object";
import { SchemaDescription } from "yup/lib/schema";
import { Maybe } from "yup/lib/types";

type ObjectSchemaOf<T extends AnyObject, CustomTypes = never> = yup.ObjectSchema<{
  [k in keyof T]-?: T[k] extends Array<infer E>
    ? yup.ArraySchema<yup.SchemaOf<E, CustomTypes> | Lazy<yup.SchemaOf<E, CustomTypes>>>
    : T[k] extends Date | CustomTypes
    ? yup.BaseSchema<Maybe<T[k]>, AnyObject, T[k]>
    : T[k] extends AnyObject
    ? ObjectSchemaOf<T[k], CustomTypes> | Lazy<ObjectSchemaOf<T[k], CustomTypes>>
    : yup.BaseSchema<Maybe<T[k]>, AnyObject, T[k]>;
}>;

export const YupNumber = () => yup.number();
export const YupString = () => yup.string().trim();
export const YupBool = () => yup.boolean().default(false);
export const YupDate = () => yup.date();
export const YupEmail = () => YupString().email();

export const isRequiredYup = <T extends AnyObject>(fieldName: keyof T, schema: ObjectSchemaOf<T, never>): boolean => {
  const { tests } = schema.describe().fields[fieldName] as SchemaDescription;
  return !!tests.find((x) => x.name === "required");
};

export const YupValidator = <T extends yup.AnyObjectSchema>(schema: T) => {
  const validator = yupResolver(schema);

  const wrapper: Resolver = async (data, context, options) => {
    const result = await validator(data, context, options);

    if (Object.keys(result.errors).length > 0) {
      console.info(data);
      console.warn(result.errors);
    }

    return result;
  };

  return wrapper as typeof validator;
};

export const castToShape = <T>(schema: yup.ObjectSchema<any>, shape: T): T => {
  return schema.cast(shape, { stripUnknown: true, assert: false }) as unknown as T;
};
