import clsx from "clsx";
import { format } from "date-fns";
import React, { forwardRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextInput } from "../TextInput/TextInput";

type DatePickerProps = ReactDatePickerProps<never, never>;

type InputDateProps = {
  label?: string;
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  required?: boolean;
  error?: string;
  className?: string;
} & Pick<DatePickerProps, "maxDate" | "minDate">;

export const InputDate: React.FC<InputDateProps> = (props) => {
  const { value = null, onChange, minDate, maxDate, label, required, error, className } = props;
  // @ts-expect-error nice
  const CustomInput = forwardRef(({ onClick }, ref) => {
    return (
      <TextInput
        label={label}
        required={required}
        onClick={onClick}
        value={value ? format(value, "P") : undefined}
        typeState=""
        error={error}
      />
    );
  });

  return (
    <div className={clsx(className)}>
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        selected={value}
        onChange={(value) => onChange?.(value)}
        customInput={<CustomInput />}
      />
    </div>
  );
};
