import { PayloadAction } from "@reduxjs/toolkit";
import { customTariffsApi } from "api/CustomTariffsApi";
import { CreateTariff } from "models/TariffDto";
import { toast } from "react-toastify";
import { call, put, select } from "redux-saga/effects";
import { getCustomTariffsAction } from "redux/customTariffsPage/customTariffsPageActions";
import { selectCustomTariffsPageState } from "redux/customTariffsPage/selectors/selectCustomTariffsState";
import { parseError } from "shared/src/helpers/errorHandler";
import { Callback } from "shared/src/models/Callback";
import { setLoadingBtnAction } from "../customTariffsPageSlice";

export function* createTariffsSaga(action: PayloadAction<CreateTariff & Callback>) {
  try {
    yield put(setLoadingBtnAction(true));
    yield call([customTariffsApi, customTariffsApi.createTariff], action.payload);
    const customTariffsPageState = yield select(selectCustomTariffsPageState);
    yield put(getCustomTariffsAction(customTariffsPageState.querySort));
    action.payload.callback?.();
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  } finally {
    yield put(setLoadingBtnAction(false));
  }
}
