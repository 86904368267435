import { PayloadAction } from "@reduxjs/toolkit";
import { UserAdminPage, usersPageApi } from "api/UsersPageApi";
import { toast } from "react-toastify";
import { call } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";

export function* addUserToCompanySaga({ payload }: PayloadAction<{ companyId: string; userId: string }>) {
  try {
    const user: UserAdminPage = yield call(
      [usersPageApi, usersPageApi.addUserToCompany],
      payload.companyId,
      payload.userId
    );
    yield call([toast, toast.success], "Success");
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
  }
}
