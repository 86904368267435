import { TStore } from "redux/reducer";
import { createSelector } from "reselect";

export const selectCustomTariffsState = (state: TStore) => state.customTariffs;

export const selectCustomTariffsPageState = createSelector([selectCustomTariffsState], (state) => state);

export const selectLoadingBtnActionState = createSelector(
  [selectCustomTariffsState],
  (state) => state.loadingBtnAction
);
