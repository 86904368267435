import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChannelsUser, SimpleSupervisor } from "models/Channels";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { defaultPaginationInfo, PaginationInfo } from "shared/src/models/Pagination";

export type ChannelsState = {
  users: ChannelsUser[];
  pagination: PaginationInfo;
  loading: boolean;
  querySort: QuerySort;
  search: string;
  btnLoading: boolean;
  allSupervisors: SimpleSupervisor[];
};

const initialState: ChannelsState = {
  users: [],
  pagination: defaultPaginationInfo,
  loading: false,
  querySort: {},
  search: "",
  btnLoading: false,
  allSupervisors: [],
};

const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    setChannelsLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setChannelsResponse: (state, action: PayloadAction<Pick<ChannelsState, "users" | "pagination">>) => {
      const { users, pagination } = action.payload;
      return { ...state, users, pagination, loading: false };
    },
    setAllSupervisors: (state, action: PayloadAction<SimpleSupervisor[]>) => {
      return { ...state, allSupervisors: action.payload };
    },
    setSearchChannel: (state, action: PayloadAction<string>) => ({ ...state, search: action.payload }),
    setQuerySortChannel: (state, action: PayloadAction<QuerySort>) => ({ ...state, querySort: action.payload }),
  },
});

export const { setChannelsLoading, setChannelsResponse, setAllSupervisors, setSearchChannel, setQuerySortChannel } =
  channelsSlice.actions;
export const channelsReducer = channelsSlice.reducer;
