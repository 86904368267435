import { PayloadAction } from "@reduxjs/toolkit";
import { GetUsersResponse, usersPageApi } from "api/UsersPageApi";
import { get } from "lodash";
import { AccountFilters } from "models/AccountFilters";
import { call, put, select } from "redux-saga/effects";
import { getPaginationFromDto } from "shared/src/models/Pagination";
import { selectUsersPageState } from "../selectors/selectUsersState";
import { getUsersAction, GetUsersActionPayload } from "../userPageActions";
import { setLoadingUserPageAction, setUserPageResponseAction, UserPageState } from "../userPageSlice";

export function* getUsersSaga(action: PayloadAction<GetUsersActionPayload>) {
  const {
    pagination: { perPage, page },
    querySort,
    search,
    accountFilters,
    group,
  }: UserPageState = yield select(selectUsersPageState);
  const pagePayload = get(action, "payload.pagination.page");
  const perPagePayload = get(action, "payload.pagination.perPage");
  const searchPayload = get(action, "payload.search");
  const groupPayload = get(action, "payload.group");

  try {
    yield put(setLoadingUserPageAction(true));
    const resp: GetUsersResponse = yield call([usersPageApi, usersPageApi.getUsers], {
      page: pagePayload ?? page,
      perPage: perPagePayload ?? perPage,
      order: querySort?.ord,
      orderBy: querySort?.ordBy,
      searchValue: searchPayload ?? search,
      corporateOnly: accountFilters.includes(AccountFilters.corporateOnly),
      accountsOnly: accountFilters.includes(AccountFilters.accountsOnly),
      testOnly: accountFilters.includes(AccountFilters.testOnly),
      notTestOnly: accountFilters.includes(AccountFilters.notTestOnly),
      group: groupPayload ?? group,
    });
    const {
      items,
      trialCount,
      activeCount,
      expiredCount,
      paymentRequiredCount,
      unsubscribedCount,
      suspendedCount,
      blockedCount,
      deletedCount,
      count,
    } = resp;
    const pagination = getPaginationFromDto(resp);

    yield put(
      setUserPageResponseAction({
        users: items,
        pagination,
        stats: {
          trialCount,
          activeCount,
          expiredCount,
          paymentRequiredCount,
          unsubscribedCount,
          suspendedCount,
          blockedCount,
          deletedCount,
          count,
        },
      })
    );
  } catch (e) {
    console.error(e);
    yield put(setLoadingUserPageAction(false));
  }
}

export function* getUsersAndClearCacheSaga() {
  yield call([usersPageApi, usersPageApi.clearUserTableCache]);
  yield getUsersSaga({ type: getUsersAction.type, payload: {} });
}
