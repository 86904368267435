import { cronTestsApi, UserForTestCronAdminPage } from "api/CronTestsApi";
import { call, put } from "redux-saga/effects";
import { setLoadingCronTestsAction, setUsersForTestCron } from "redux/cronTests/cronTestsSlice";

export function* getUsersForTestCronSage() {
  try {
    yield put(setLoadingCronTestsAction(true));
    const resp: UserForTestCronAdminPage[] = yield call([cronTestsApi, cronTestsApi.getUsersForTestCron]);
    yield put(setUsersForTestCron(resp));
  } catch (e) {
    console.error(e);
    yield put(setLoadingCronTestsAction(false));
  }
}
