import { Pagination } from "shared/src/models/Pagination";

export enum ChannelsTypeList {
  supervisor = "supervisors",
  partner = "partners",
}

export type ChannelsUser = {
  id: string;
  email: string;
  name: string;
  master_application_key: string;
  created_at: Date;
  supervisor_id?: string;
};

export type GetChannelsUsersResponseDto = Pagination<ChannelsUser>;

export type SupervisorForm = Pick<ChannelsUser, "name" | "master_application_key" | "email">;
export type SupervisorEditForm = Pick<ChannelsUser, "name" | "master_application_key" | "email" | "id">;
export type SimpleSupervisor = Pick<ChannelsUser, "id" | "email">;
export type CreateSupervisor = Pick<ChannelsUser, "name" | "email">;
export type CreatePartner = Pick<ChannelsUser, "name" | "email" | "supervisor_id">;
export type PartnerEditForm = Pick<ChannelsUser, "name" | "email" | "supervisor_id" | "id">;
