import { Auth } from "components/Auth/Auth";
import { Layout } from "components/Layout/Layout";
import { Channels } from "pages/Channels/Channels";
import { CronTests } from "pages/CronTests/CronTests";
import CustomTariffs from "pages/CustomTariffs/CustomTariffs";
import { Login } from "pages/Login/Login";
import { Statistics } from "pages/Statistics/Statistics";
import { UserInfo } from "pages/UserInfo/UserInfo";
import { UsersPage } from "pages/UsersPage/UsersPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteEnums } from "router/routeEnums";
import "styles/_common.scss";

export const App = () => {
  return (
    <BrowserRouter>
      <Auth>
        <Routes>
          <Route path={RouteEnums.root} element={<Layout />}>
            <Route path={RouteEnums.root} element={<Statistics />} />
            <Route path={RouteEnums.users} element={<UsersPage />} />
            <Route path={RouteEnums.customTariffs} element={<CustomTariffs />} />
            <Route path={RouteEnums.user} element={<UserInfo />} />
            <Route path={RouteEnums.channels} element={<Channels />} />
            <Route path={RouteEnums.cronTests} element={<CronTests />} />
          </Route>
          <Route path={RouteEnums.login} element={<Login />} />
        </Routes>
      </Auth>
    </BrowserRouter>
  );
};
