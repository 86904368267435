import React from "react";
import { CommonTable, TableItem } from "shared/src/components/CommonTable/CommonTable";
import { Heading } from "shared/src/components/Heading/Heading";
import { StripeCard } from "shared/src/models/StripeCard";
import styles from "./userInfo.module.scss";

type PaymentMethodProps = {
  loading: boolean;
  cards?: StripeCard[];
};

export const PaymentMethod: React.FC<PaymentMethodProps> = ({ loading, cards = [] }) => {
  const items: TableItem<typeof cards[0]>[] = [
    {
      label: "Last 4 digits",
      render: (x) => (
        <div className={styles.cardAssetWrapper}>
          <img
            className={styles.cardAsset}
            src={require(`shared/src/images/verify-card/${x.brand}.svg`).default}
            alt={x.brand}
          />
          · {x.last4}
        </div>
      ),
    },
    {
      label: "Expires on",
      render: (x) => (
        <span>
          {x.expMonth}/{x.expYear.slice(2)}
        </span>
      ),
    },
    { label: "Status", render: (x) => <span>{x.default ? "Default" : "Backup"}</span> },
  ];

  return (
    <div className={styles.paymentMethod}>
      <Heading className={styles.paymentMethodTitle} variant="h2">
        Payment method
      </Heading>
      <CommonTable loading={loading} items={items} values={cards} idKey="id" />
    </div>
  );
};
