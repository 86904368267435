import { usersPageApi } from "api/UsersPageApi";
import { MinimalCompany } from "models/MinimalCompany";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { setAllMinCompanies } from "redux/userPage/userPageSlice";
import { parseError } from "shared/src/helpers/errorHandler";

export function* getMinimalAllCompaniesSaga() {
  try {
    const companies: MinimalCompany[] = yield call([usersPageApi, usersPageApi.getMinAllCompanies]);
    yield put(setAllMinCompanies(companies));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
  }
}
