import { UserAdminPage } from "api/UsersPageApi";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllMinCompanies } from "redux/userPage/selectors/selectAllMinCompanies";
import { addCompanyToUser, getMinimalAllCompanies } from "redux/userPage/userPageActions";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { RightDrawer } from "shared/src/components/RightDrawer/RightDrawer";
import { SelectField } from "shared/src/components/Select/SelectField";
import styles from "./addUserToCompany.module.scss";

type AddUserToCompanyProps = {
  open: boolean;
  onClose: () => void;
  user: UserAdminPage;
};

export const AddUserToCompany: React.FC<AddUserToCompanyProps> = ({ open, onClose, user }) => {
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(user?.company?.id);
  const allMinCompanies = useSelector(selectAllMinCompanies);
  const allMinCompaniesOptions = useMemo(() => {
    return allMinCompanies.map((x) => ({ value: x.id, label: x.name }));
  }, [allMinCompanies]);

  const save = () => {
    if (selectedCompany && user.companyId !== selectedCompany) {
      dispatch(
        addCompanyToUser({
          companyId: selectedCompany,
          userId: user.id,
        })
      );
    }
    onClose();
  };

  useEffect(() => {
    if (open) {
      dispatch(getMinimalAllCompanies());
    }
  }, [open, user.id]);

  return (
    <RightDrawer
      footer={
        <div className={styles.actions}>
          <PbButton onClick={onClose} className={styles.action} size="md" color="secondary">
            Cancel
          </PbButton>
          <PbButton onClick={save} type="submit" className={styles.action} size="md" color="primary">
            Save
          </PbButton>
        </div>
      }
      open={open}
      onClose={onClose}
      title="Add user to company"
    >
      <div>
        <SelectField
          value={selectedCompany}
          options={allMinCompaniesOptions}
          label="Add user to company"
          onChange={(cId) => setSelectedCompany(cId)}
        />
      </div>
    </RightDrawer>
  );
};
