import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { channelsSaga } from "redux/channels/sagasWatcher";
import { cronTestsPageSaga } from "redux/cronTests/sagasWatcher";
import { rootReducer } from "redux/reducer";
import { statisticsSaga } from "redux/statistics/sagasWatcher";
import { userSaga } from "redux/user/sagasWatcher";
import { catalogsSaga } from "./catalogs/sagasWatcher";
import { customTariffsPageSaga } from "./customTariffsPage/sagasWatcher";
import { userPageSaga } from "./userPage/sagasWatcher";

const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });

  function* rootSaga() {
    yield all([fork(userSaga)]);
    yield all([fork(statisticsSaga)]);
    yield all([fork(userPageSaga)]);
    yield all([fork(catalogsSaga)]);
    yield all([fork(customTariffsPageSaga)]);
    yield all([fork(channelsSaga)]);
    yield all([fork(cronTestsPageSaga)]);
  }

  sagaMiddleware.run(rootSaga);

  return store;
};
