import React from "react";
import Select, { MultiValue, OptionsOrGroups } from "react-select";
import { AnyObject } from "yup/lib/types";
import styles from "./selectMulti.module.scss";

export type OptionType<T> = { label: string; value: T };

export type SelectFieldProps<T> = {
  onChange: (x?: MultiValue<OptionType<T>>) => void;
  className?: string;
  value?: T;
  options: OptionsOrGroups<OptionType<T>, never>;
  label?: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
};

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: "#1B2A47",
    padding: 12,
    backgroundColor: "#ffffff",
    cursor: "pointer",
  }),
  control: (base, state) => ({
    ...base,
    border: "0px solid #6e7e9d4d",
    boxShadow: state.isFocused ? "0 0 0 1px #6e7e9d4d" : "0 0 0 1px #6e7e9d4d",
    padding: "5px 12px 5px 8px",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    width: "100%",
    fontSize: "14px",
  }),

  menuPortal: (base) => ({
    ...base,
    zIndex: 1051,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity };
  },
};

export function SelectMulti<T>({
  options,
  label,
  required,
  placeholder,
  onChange,
  value,
  className,
}: SelectFieldProps<T>) {
  return (
    <div className={className}>
      {label && (
        <div className={styles.label}>
          <span className={styles.labelText}>{label}</span>
          {required && <span className={styles.redDot}>·</span>}
        </div>
      )}
      <Select
        isMulti
        menuPortalTarget={document.body}
        onChange={(x) => onChange(x)}
        styles={customStyles}
        placeholder={placeholder}
        required={required}
        options={options}
        // todo test w/ object value type, mb need improve code
        value={options.find((x) => x.value === value)}
      />
    </div>
  );
}

export const enumToOptions = <T extends AnyObject>(_enum: T): OptionsOrGroups<OptionType<string>, never> => {
  const enumValues = Object.values(_enum).filter((x) => isNaN(Number(x)));
  return enumValues.map((x) => ({ value: x, label: x }));
};
