import { catalogApi } from "api/CatalogApi";
import { toast } from "react-toastify";
import { all, call, put } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";
import { setDataVolumes, setTariffCatalog } from "../catalogSlice";

export function* getCatalogSaga() {
  try {
    const [tariffCatalog, dataVolumesCatalog] = yield all([
      call([catalogApi, catalogApi.getTarriffCatalog]),
      call([catalogApi, catalogApi.getDataVolumesCatalog]),
    ]);
    yield put(setTariffCatalog(tariffCatalog));
    yield put(setDataVolumes(dataVolumesCatalog));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
