import { EyeEmpty, EyeOff, Refresh } from "iconoir-react";
import { useState } from "react";
import { SelectField, SelectFieldProps } from "../Select/SelectField";
import { TextInput } from "../TextInput/TextInput";
import { CntrlWrap, CtrlProps } from "./CntrlWrap";

type ControlledTextInputProps = {
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  labelExtraContent?: JSX.Element;
  className?: string;
  inputSign?: string;
  type?: "text" | "password" | "passwordVisible" | "email" | "number";
  textArea?: boolean;
  refreshAction?: () => void;
};

export function ControlledText<T>(props: CtrlProps<T, string | number, ControlledTextInputProps>) {
  const {
    control,
    name,
    label,
    required,
    placeholder,
    type = "text",
    disabled,
    labelExtraContent,
    className,
    inputSign,
    textArea,
    refreshAction,
  } = props;

  const [typeState, setTypeState] = useState(type);
  const changeType = () => setTypeState(typeState === "password" ? "text" : "password");
  const setIcon = () => {
    if (type === "password") {
      return typeState === "password" ? (
        <EyeEmpty color="#D4D7E0" fontSize={20} />
      ) : (
        <EyeOff color="#D4D7E0" fontSize={20} />
      );
    } else if (!!refreshAction) {
      return <Refresh onClick={refreshAction} color="#6E7E9D" fontSize={15} />;
    }

    return undefined;
  };
  return (
    <CntrlWrap
      control={control}
      name={name}
      render={(value, onChange, error) => {
        return (
          <TextInput
            textArea={textArea}
            labelExtraContent={labelExtraContent}
            label={label}
            placeholder={placeholder}
            type={type}
            typeState={typeState}
            icon={setIcon()}
            changeType={changeType}
            value={(value as string)?.toString()}
            onChange={onChange}
            error={error}
            required={required}
            className={className}
            disabled={disabled}
            inputSign={inputSign}
          />
        );
      }}
    />
  );
}

export function ControlledSelect<TSchema, TValue>(
  props: CtrlProps<TSchema, TValue | undefined, Omit<SelectFieldProps<TValue>, "value" | "onChange">>
) {
  const { control, name, ...selectProps } = props;

  return (
    <CntrlWrap
      control={control}
      name={name}
      render={(value, onChange, error) => {
        return <SelectField {...selectProps} value={value} onChange={onChange} error={error} />;
      }}
    />
  );
}
