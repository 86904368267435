import { dashClient } from "helpers/HttpClient";
import { StatisticsCommon } from "models/StatisticsCommon";
import { put } from "redux-saga/effects";
import { setStatisticsCommon } from "redux/statistics/statisticsSlice";

export function* getStatisticsCommonSaga() {
  try {
    const statisticsCommon: StatisticsCommon = yield dashClient.apiGet({ url: "/admin/statistics/common" });
    yield put(setStatisticsCommon(statisticsCommon));
  } catch (e) {
    console.error(e);
  }
}
