import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useDataVolumeOptions } from "redux/catalogs/selectors/selectDataVolumesCatalog";
import { useGetRelatedCatalog } from "redux/catalogs/selectors/selectTariffCatalog";
import { getCustomTariffsAction } from "redux/customTariffsPage/customTariffsPageActions";
import { selectUserPageCards } from "redux/userPage/selectors/selectUserPage";
import {
  castToShape,
  isRequiredYup,
  YupBool,
  YupDate,
  YupEmail,
  YupNumber,
  YupString,
  YupValidator,
} from "shared/src/common/yup";
import { ControlledSelect, ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { RightDrawer } from "shared/src/components/RightDrawer/RightDrawer";
import { enumToOptions } from "shared/src/components/Select/SelectField";
import { countriesList } from "shared/src/data/countries";
import { UpdateCompanyDto } from "shared/src/models/Company";
import { UpdateUserDto, UserRole, UserStatusEnum } from "shared/src/models/UserModel";
import * as yup from "yup";
import styles from "./EditUserForm.module.scss";

// @ts-expect-error nice
const UpdateCompanySchema: yup.SchemaOf<UpdateCompanyDto> = yup.object({
  id: YupString().required(),
  name: YupString(),
  country: YupString(),
  dataVolume: YupString(),
  createdAt: YupDate(),
  phone: YupString().default(undefined).nullable(),
});

const UpdateUserSchema: yup.SchemaOf<UpdateUserDto> = yup.object({
  id: YupString().required(),
  email: YupEmail().required(),
  name: YupString().required(),
  tariffId: YupNumber().nullable(),
  status: YupString().required(),
  role: YupString().required().default(UserRole.CUSTOMER),
  password: YupString().default(undefined),
  company: UpdateCompanySchema.default(undefined),
  isCanShare: YupBool(),
});

type EditUserFormInput = UpdateUserDto & { companyId?: string };

type EditUserModalFormProps = {
  onClose: () => void;
  active: boolean;
  user: EditUserFormInput;
  onSubmit: (dto: UpdateUserDto) => void;
};

export const EditUserForm: React.FC<EditUserModalFormProps> = (props) => {
  const { onClose, active, user, onSubmit } = props;
  const { control, trigger, getValues } = useForm<UpdateUserDto>({
    resolver: YupValidator(UpdateUserSchema),
    defaultValues: {
      ...user,
      company: user.company
        ? {
            ...user.company,
            country: _.capitalize(user.company?.country),
          }
        : undefined,
    },
  });
  const isCorporate = Boolean(user.companyId || user.company?.id);
  const relatedTariffs = useGetRelatedCatalog(isCorporate);
  const tariffOptions = useMemo(() => {
    return relatedTariffs.map((x) => ({ value: x.id, label: x.name }));
  }, [relatedTariffs]);
  const dataVolumeOptions = useDataVolumeOptions();

  const dispatch = useDispatch();
  const [loading, userInfoPaymentMethod] = useSelector(selectUserPageCards);
  const changeTariffProhibited = userInfoPaymentMethod?.length === 0 && !isCorporate;

  const checkRequiredField = (key: keyof UpdateUserDto) => {
    return isRequiredYup(key, UpdateUserSchema);
  };

  const submit = async () => {
    if (!(await trigger())) return;
    const formValues = getValues();
    const dto = castToShape(UpdateUserSchema, formValues);
    onSubmit(dto);
    // dispatch(updateUserAction({ userDto: dto, onSuccess: onClose }));
  };

  useEffect(() => {
    if (active && isCorporate) {
      dispatch(getCustomTariffsAction());
    }
  }, [isCorporate, active]);

  // useEffect(() => {
  //   if (active && !isCorporate) {
  //     dispatch(getUserInfo({ id: user.id, query: { page: 1 } }));
  //   }
  // }, [active, user.id, isCorporate]);

  return (
    <RightDrawer
      open={active}
      onClose={onClose}
      title="Edit"
      footer={
        <div className={styles.footer}>
          <PbButton onClick={onClose} size="md" color="secondary">
            Cancel
          </PbButton>
          <PbButton onClick={submit} type="submit" size="md" color="primary">
            Update
          </PbButton>
        </div>
      }
    >
      <div className={styles.formWrapper}>
        <ControlledText label="User Name" required={checkRequiredField("name")} control={control} name="name" />
        <ControlledText label="User Email" required={checkRequiredField("email")} control={control} name="email" />
        <ControlledSelect
          options={enumToOptions(UserStatusEnum)}
          label="User Status"
          required={checkRequiredField("status")}
          control={control}
          name="status"
        />
        <ControlledSelect
          options={tariffOptions}
          label="User Plan"
          required={checkRequiredField("tariffId")}
          control={control}
          name="tariffId"
          disabled={changeTariffProhibited}
        />

        {isCorporate && (
          <>
            <ControlledText
              required
              className={styles.field}
              label="Company name"
              control={control}
              name="company.name"
              type="text"
            />
            <ControlledSelect
              className={styles.field}
              options={countriesList}
              label="Country"
              placeholder="Choose a country"
              name="company.country"
              control={control}
              required
            />
            <ControlledText className={styles.field} label="Phone" control={control} name="company.phone" type="text" />
            <ControlledSelect
              className={styles.field}
              options={dataVolumeOptions}
              label="Storage amount"
              placeholder="Choose storage"
              name="company.dataVolume"
              control={control}
              required
            />
          </>
        )}
        <ControlledText
          label="Change Password"
          required={checkRequiredField("password")}
          control={control}
          name="password"
        />
      </div>
    </RightDrawer>
  );
};
