import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectUsersPageBtnLoading } from "redux/userPage/selectors/selectUsersState";
import { ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { CoreModal } from "shared/src/components/Modal/Modal";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { UserProfile } from "shared/src/models/UserModel";
import * as yup from "yup";
import styles from "./extendTrialModal.module.scss";

type ExtendTrialModalProps = {
  user: UserProfile;
  active: boolean;
  onClose: () => void;
  onSubmit: (days: number) => void;
};

export const ExtendTrialModal: React.FC<ExtendTrialModalProps> = (props) => {
  const { active = false, onClose, onSubmit } = props;
  const loading = useSelector(selectUsersPageBtnLoading);
  const { control, trigger, getValues } = useForm<{ days: number }>({
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        days: yup.string().trim().required("This field is required"),
      })
    ),
    defaultValues: {
      days: 30,
    },
  });

  const submit = async () => {
    if (!(await trigger())) return;
    const formData = getValues();
    onSubmit(formData.days);
  };

  return (
    <CoreModal
      footer={
        <div className={styles.footer}>
          <PbButton onClick={onClose} color="secondary-bordered" className={styles.btn}>
            Cancel
          </PbButton>
          <PbButton onClick={submit} loading={loading} disabled={loading} color="success" className={styles.btn}>
            Save
          </PbButton>
        </div>
      }
      className={styles.modal}
      title="Extend Trial"
      closeOutClick
      visible={active}
      onClose={onClose}
    >
      <ControlledText
        className={styles.field}
        label="Trial validity period,  days"
        control={control}
        name="days"
        type="number"
      />
    </CoreModal>
  );
};
