import { PayloadAction } from "@reduxjs/toolkit";
import { cronTestsApi } from "api/CronTestsApi";
import { SetUserPlanDurationDto } from "models/SetUserPlanDuration";
import { call } from "redux-saga/effects";
import { getUsersForTestCronSage } from "redux/cronTests/sagas/getUsersForTestCronSage";

export function* triggerCronSaga(action: PayloadAction<SetUserPlanDurationDto>) {
  try {
    yield call([cronTestsApi, cronTestsApi.setPlanDurationUsersForTestCron], action.payload);
    yield call([cronTestsApi, cronTestsApi.triggerCron]);
    yield call(getUsersForTestCronSage);
  } catch (e) {
    console.error(e);
  }
}
