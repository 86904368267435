import { PayloadAction } from "@reduxjs/toolkit";
import { usersPageApi } from "api/UsersPageApi";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";
import { getUserPageUserInfo, getUsersAction, UpdateUserActionPayload } from "../userPageActions";

export function* updateUserTableSaga(action: PayloadAction<UpdateUserActionPayload>) {
  const { userDto, onSuccess } = action.payload;

  try {
    yield call([usersPageApi, usersPageApi.updateUser], userDto.id, userDto);
    yield call([toast, toast.success], "User successfully updated");
    onSuccess();
    yield put(getUsersAction());
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}

export function* updateUserPageSaga(action: PayloadAction<UpdateUserActionPayload>) {
  const { userDto, onSuccess } = action.payload;

  try {
    yield call([usersPageApi, usersPageApi.updateUser], userDto.id, userDto);
    yield put(getUserPageUserInfo({ id: action.payload.userDto.id }));
    yield call([toast, toast.success], "User successfully updated");
    onSuccess();
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
