import { dashClient } from "helpers/HttpClient";
import {
  ChannelsUser,
  CreatePartner,
  CreateSupervisor,
  GetChannelsUsersResponseDto,
  PartnerEditForm,
  SupervisorForm,
} from "models/Channels";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { BaseRestClient, withParams } from "shared/src/http";

export type GetChannelsQuery = {
  page?: number;
  perPage?: number;
  search?: string;
  order?: QuerySort["ord"];
  orderBy?: QuerySort["ordBy"];
};
export class ChannelsApi {
  dashClient: BaseRestClient;

  constructor() {
    this.dashClient = dashClient;
  }

  async getChannelsList(type, query = {} as GetChannelsQuery): Promise<GetChannelsUsersResponseDto> {
    const url = withParams(`/admin/channels/${type}`, query);

    const rep = await this.dashClient.apiGet<GetChannelsUsersResponseDto>({ url });
    return rep;
  }
  async createSupervisor(data: CreateSupervisor) {
    const rep = await this.dashClient.apiPost({ url: "/admin/channels/supervisors", data });
    return rep;
  }
  async editSupervisor(data: SupervisorForm, id: string) {
    const rep = await this.dashClient.apiPut({ url: `/admin/channels/supervisors/${id}`, data });
    return rep;
  }
  async createPartner(data: CreatePartner) {
    const rep = await this.dashClient.apiPost({ url: "/admin/channels/partners", data });
    return rep;
  }
  async editPartner(data: PartnerEditForm, id: string) {
    const rep = await this.dashClient.apiPut({ url: `/admin/channels/partners/${id}`, data });
    return rep;
  }
  async reGenerateMasterKey(id: string, type: string): Promise<ChannelsUser> {
    const rep = await this.dashClient.apiPut<ChannelsUser>({ url: `/admin/channels/${type}/master-key/${id}` });
    return rep;
  }
}
export const channelsApi = new ChannelsApi();
