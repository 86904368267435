import { PayloadAction } from "@reduxjs/toolkit";
import { channelsApi } from "api/ChannelsApi";
import { ChannelsTypeList, ChannelsUser } from "models/Channels";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { getSupervisorsOrPartnersList } from "redux/channels/channelsActions";
import { setChannelsLoading } from "redux/channels/channelsSlice";
import { parseError } from "shared/src/helpers/errorHandler";

export function* reGenerateMasterKeySaga(action: PayloadAction<{ id: string; type: ChannelsTypeList }>) {
  try {
    yield put(setChannelsLoading(true));
    const resp: ChannelsUser = yield call(
      [channelsApi, channelsApi.reGenerateMasterKey],
      action.payload.id,
      action.payload.type === ChannelsTypeList.partner ? "partner" : "supervisor"
    );
    yield put(getSupervisorsOrPartnersList({ type: action.payload.type }));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
