import { cronTestsApi } from "api/CronTestsApi";
import { call, put } from "redux-saga/effects";
import { setLoadingCronTestsAction } from "redux/cronTests/cronTestsSlice";
import { getUsersForTestCronSage } from "redux/cronTests/sagas/getUsersForTestCronSage";

export function* clearUsersForTestCronSage() {
  try {
    yield put(setLoadingCronTestsAction(true));
    yield call([cronTestsApi, cronTestsApi.clearUsersForTestCron]);
    yield call(getUsersForTestCronSage);
  } catch (e) {
    console.error(e);
    yield put(setLoadingCronTestsAction(false));
  }
}
