import { PayloadAction } from "@reduxjs/toolkit";
import { channelsApi } from "api/ChannelsApi";
import { get } from "lodash";
import { GetChannelsUsersResponseDto } from "models/Channels";
import { toast } from "react-toastify";
import { call, put, select } from "redux-saga/effects";
import { GetSupervisorsOrPartnersPayload } from "redux/channels/channelsActions";
import { ChannelsState, setChannelsLoading, setChannelsResponse } from "redux/channels/channelsSlice";
import { selectChannelsPageState } from "redux/channels/selectors/selectChannelsState";
import { parseError } from "shared/src/helpers/errorHandler";
import { getPaginationFromDto } from "shared/src/models/Pagination";

export function* getSupervisorsOrPartnersListSaga(action: PayloadAction<GetSupervisorsOrPartnersPayload>) {
  try {
    const {
      pagination: { perPage, page },
      querySort,
      search,
    }: ChannelsState = yield select(selectChannelsPageState);
    const pagePayload = get(action, "payload.pagination.page");
    const perPagePayload = get(action, "payload.pagination.perPage");
    const searchPayload = get(action, "payload.search");

    yield put(setChannelsLoading(true));
    const resp: GetChannelsUsersResponseDto = yield call(
      [channelsApi, channelsApi.getChannelsList],
      action.payload.type,
      {
        page: pagePayload ?? page,
        perPage: perPagePayload ?? perPage,
        order: querySort?.ord,
        orderBy: querySort?.ordBy,
        search: searchPayload ?? search,
      }
    );
    const pagination = getPaginationFromDto(resp);
    yield put(
      setChannelsResponse({
        pagination,
        users: resp.items,
      })
    );
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
