import { takeLatest } from "redux-saga/effects";
import { addUserToCompanySaga } from "redux/userPage/sagas/addUserToCompanySaga";
import { deleteUserSaga } from "redux/userPage/sagas/deleteUserSaga";
import { freezeUserSaga } from "redux/userPage/sagas/freezeUserSaga";
import { getMinimalAllCompaniesSaga } from "redux/userPage/sagas/getMinimalAllCompaniesSaga";
import { createInvoicePartnerSaga } from "./sagas/createInvoicePartnerSaga";
import { createInvoiceSaga } from "./sagas/createInvoiceSaga";
import { createNewUserSaga } from "./sagas/createNewUserSaga";
import { extendUserTrialSaga } from "./sagas/extendUserTrialSaga";
import { getUsersAndClearCacheSaga, getUsersSaga } from "./sagas/getUsersSaga";
import { updateUserPageSaga, updateUserTableSaga } from "./sagas/updateUserSaga";
import {
  getUserPageBillingHistorySaga,
  getUserPageCardsSaga,
  getUserPageStoredFilesSaga,
  getUserPageTicketsSaga,
  getUserPageUserInfoSaga,
  getUserTariffsHistorySaga,
} from "./sagas/userPageSagas";
import {
  addCompanyToUser,
  clearUsersTableCache,
  createInvoiceAction,
  createInvoicePartnerAction,
  createNewUserAction,
  deleteUser,
  extendUserTrialAction,
  freezeUser,
  getMinimalAllCompanies,
  getUserPageBillingHistory,
  getUserPageCards,
  getUserPageStoredFiles,
  getUserPageTickets,
  getUserPageUserInfo,
  getUsersAction,
  getUserTariffsHistory,
  updateUserPageAction,
  updateUserTableAction,
} from "./userPageActions";

export function* userPageSaga() {
  yield takeLatest(getUsersAction.type, getUsersSaga);
  yield takeLatest(createNewUserAction.type, createNewUserSaga);
  yield takeLatest(updateUserTableAction.type, updateUserTableSaga);
  yield takeLatest(extendUserTrialAction.type, extendUserTrialSaga);
  yield takeLatest(createInvoiceAction.type, createInvoiceSaga);
  yield takeLatest(createInvoicePartnerAction.type, createInvoicePartnerSaga);
  yield takeLatest(freezeUser.type, freezeUserSaga);
  yield takeLatest(deleteUser.type, deleteUserSaga);
  yield takeLatest(getMinimalAllCompanies.type, getMinimalAllCompaniesSaga);
  yield takeLatest(addCompanyToUser.type, addUserToCompanySaga);
  yield takeLatest(clearUsersTableCache.type, getUsersAndClearCacheSaga);

  // user Page sagas
  yield takeLatest(getUserTariffsHistory.type, getUserTariffsHistorySaga);
  yield takeLatest(getUserPageUserInfo.type, getUserPageUserInfoSaga);
  yield takeLatest(getUserPageStoredFiles.type, getUserPageStoredFilesSaga);
  yield takeLatest(getUserPageTickets.type, getUserPageTicketsSaga);
  yield takeLatest(getUserPageCards.type, getUserPageCardsSaga);
  yield takeLatest(getUserPageBillingHistory.type, getUserPageBillingHistorySaga);
  yield takeLatest(updateUserPageAction.type, updateUserPageSaga);
}
