import { Cancel } from "iconoir-react";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import SvgSearch from "../icons/SearchSvg";
import { TextInput } from "../TextInput/TextInput";

type SearchInputProps = {
  onChange?: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
};

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { onChange, defaultValue, ...inputProps } = props;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    // when defaultValues changed to "", probably than means that filters was reset
    // so reset local value too
    if (defaultValue === "") setValue("");
  }, [defaultValue]);

  const onChangeDebounced = useCallback(
    debounce(async (newValue: string) => {
      onChange?.(newValue);
    }, 500),
    []
  );

  const setNewValue = (newValue: string) => {
    setValue(newValue);
    onChangeDebounced(newValue);
  };

  const clear = () => {
    setNewValue("");
    onChange?.("");
  };

  return (
    <TextInput
      icon={value ? <Cancel onClick={clear} /> : <SvgSearch />}
      typeState="text"
      {...inputProps}
      value={value}
      onChange={setNewValue}
    />
  );
};
