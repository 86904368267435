import { dashClient } from "helpers/HttpClient";
import { CorporateAccountFields, DefaultAccountFields } from "models/AddNewAccount";
import { CreateInvoiceDto, PartnerInvoiceDto } from "models/CreateInvoice.dto";
import { MinimalCompany } from "models/MinimalCompany";
import { TariffDto } from "models/TariffDto";
import { UserInfo, UserInfo2 } from "models/UserInfo";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { BaseRestClient, withParams } from "shared/src/http";
import { BillingHistoryDto, BillingHistoryTO } from "shared/src/models/BillingHistory.model";
import { Pagination, PaginationQueryParams } from "shared/src/models/Pagination";
import { BandwidthAnalyticsResponse, StorageUsageAnalyticsResponse } from "shared/src/models/Statistics.dto";
import { StripeCard } from "shared/src/models/StripeCard";
import { Ticket } from "shared/src/models/Ticket";
import { UpdateUserDto, UserProfile } from "shared/src/models/UserModel";
import { UserPlanSnapshotDto } from "shared/src/models/UserPlanSnapshotDto";

type FileSize = {
  quantity: string;
  unit: string;
};

export function fileSizeTsGuard(x: FileSize | string): x is FileSize {
  return typeof x === "object" && "quantity" in x;
}

export type GetUserBillingQuery = { group?: "all" | "success" | "pending" | "failed" } & PaginationQueryParams;

export type UserAdminPage = UserProfile & {
  filesCount: number;
  // todo switch to string everywhere
  filesSize: string;
  openTicketsCount: number;
  plan?: {
    id: number;
    name: string;
    created_at: Date;
    end_at: Date;
    tariff_id: number;
    user_id: string;
    active: boolean;
  };
  tariff: TariffDto;
  companyId?: string;
};

export type GetUsersResponseStats = {
  trialCount: number;
  activeCount: number;
  expiredCount: number;
  paymentRequiredCount: number;
  unsubscribedCount: number;
  suspendedCount: number;
  blockedCount: number;
  deletedCount: number;
  count: number;
};

export type GetUsersResponse = Pagination<UserAdminPage> & GetUsersResponseStats;

export type GetUsersQuery = {
  page?: number;
  perPage?: number;
  searchValue?: string;
  order?: QuerySort["ord"];
  orderBy?: QuerySort["ordBy"];
  corporateOnly?: boolean;
  accountsOnly?: boolean;
  testOnly?: boolean;
  notTestOnly?: boolean;
  group?:
    | "all"
    | "trial"
    | "active"
    | "expired"
    | "payment_required"
    | "unsubscribed"
    | "suspended"
    | "blocked"
    | "deleted";
};

export type GetUserStoredFilesResponse = {
  bucketsCount: number;
  filesCount: number;
  foldersCount: number;
  filesSize: string;
};

type GetStatisticQuery = {
  fromDate?: string;
  toDate?: string;
};

export class UsersPageApi {
  dashClient: BaseRestClient;

  constructor() {
    this.dashClient = dashClient;
  }

  async getUsers(query = {} as GetUsersQuery): Promise<GetUsersResponse> {
    const url = withParams("/admin/users", query);
    const rep = await this.dashClient.apiGet<GetUsersResponse>({ url });
    return rep;
  }

  async createUser(data: DefaultAccountFields): Promise<void> {
    const rep = await this.dashClient.apiPost<void>({ url: "/admin/users/create-verified-user/physical", data });
    return rep;
  }

  async createCorporateUser(data: CorporateAccountFields): Promise<void> {
    const rep = await this.dashClient.apiPost<void>({ url: "/admin/users/create-verified-user/corporate", data });
    return rep;
  }

  async updateUser(id: string, data: UpdateUserDto): Promise<void> {
    await this.dashClient.apiPut({ url: `/admin/users/${id}`, data });
  }

  async extendTrial(userId: string, days: number): Promise<void> {
    await this.dashClient.apiPut<void>({ url: `/admin/users/${userId}/extend-trial`, data: { days } });
  }

  async createInvoice(userId: string, payload: CreateInvoiceDto): Promise<BillingHistoryDto> {
    const res = this.dashClient.apiPost<BillingHistoryDto>({ url: `/billing/invoice/${userId}`, data: payload });
    return res;
  }

  async createInvoicePartner(userId: string, payload: PartnerInvoiceDto): Promise<void> {
    const res = this.dashClient.apiPost<void>({
      url: `/billing/invoice/partner/${userId}`,
      data: payload,
    });
    return res;
  }

  async getUserInfo(id: string, query: PaginationQueryParams): Promise<UserInfo> {
    const url = withParams(`/admin/users/${id}`, query);
    return await this.dashClient.apiGet({ url });
  }

  async getUserTariffsHistory(id: string, query: PaginationQueryParams): Promise<Pagination<UserPlanSnapshotDto>> {
    const url = withParams(`/admin/users/tariffs-snapshot/${id}`, query);
    return await this.dashClient.apiGet({ url });
  }

  async freezeUser(id: string): Promise<void> {
    return await this.dashClient.apiPut({ url: `/admin/users/${id}/freeze` });
  }

  async deleteUser(id: string, reason?: string): Promise<void> {
    return await this.dashClient.apiDelete({ url: `/admin/users/${id}`, data: { reason } });
  }

  async getMinAllCompanies(): Promise<MinimalCompany[]> {
    return await this.dashClient.apiGet({ url: "/admin/users/companies/all" });
  }

  async addUserToCompany(companyId: string, userId): Promise<void> {
    return await this.dashClient.apiPut({ url: `/admin/users/company/add/${companyId}/${userId}` });
  }

  async getBandiwthStatistic(userId: string, query: GetStatisticQuery = {}) {
    const url = withParams(`/admin/bandwidth-analytics/${userId}`, query);
    const rep = await this.dashClient.apiGet<BandwidthAnalyticsResponse>({ url });
    return rep;
  }

  async getStorageStatistic(userId: string, query: GetStatisticQuery = {}) {
    const url = withParams(`/admin/storage-analytics/${userId}`, query);
    const rep = await this.dashClient.apiGet<StorageUsageAnalyticsResponse>({ url });
    return rep;
  }

  async clearUserTableCache() {
    return this.dashClient.apiCall({ url: "/admin/users/user-table/drop/cache" });
  }

  async getUserInfo2(id: string) {
    return this.dashClient.apiGet<UserInfo2>({ url: `/admin/users/user-info/${id}` });
  }

  async getStoredFilesInfo(id: string) {
    return this.dashClient.apiGet<GetUserStoredFilesResponse>({ url: `/admin/users/stored-files-info/${id}` });
  }

  async getUserTickets(id: string, status?: "open" | "close") {
    const url = withParams(`/admin/users/user-tickets/${id}`, status ? { status } : {});
    return this.dashClient.apiGet<Ticket[]>({ url });
  }

  async getUserCards(id: string) {
    return this.dashClient.apiGet<StripeCard[]>({ url: `/admin/users/user-cards/${id}` });
  }

  async getUserBillingHistory(id: string, query?: GetUserBillingQuery) {
    return this.dashClient.apiGet<BillingHistoryTO>({ url: `/admin/users/user-billing-history/${id}` });
  }
}

export const usersPageApi = new UsersPageApi();
