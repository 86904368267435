import { differenceInCalendarDays } from "date-fns";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserPageUserPlanSnapshot } from "redux/userPage/selectors/selectUserPage";
import { getUserTariffsHistory } from "redux/userPage/userPageActions";
import { setUserPlanSnapshot } from "redux/userPage/userPageSlice";
import { CommonTable, TableItem } from "shared/src/components/CommonTable/CommonTable";
import { Heading } from "shared/src/components/Heading/Heading";
import { PageSwitch } from "shared/src/components/PageSwitch/PageSwitch";
import { PerPageSwitcher } from "shared/src/components/PerPageSwitcher/PerPageSwitcher";
import { centsToUsd, prettySize } from "shared/src/helpers/utils";
import { UserPlanSnapshotDto } from "shared/src/models/UserPlanSnapshotDto";
import styles from "./userTariffsHistory.module.scss";

export const UserTariffsHistory: React.FC<{ userId: string }> = ({ userId }) => {
  const [loading, userPlanSnapshot] = useSelector(selectUserPageUserPlanSnapshot);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserTariffsHistory({ id: userId, query: { page: 1 } }));
    return () => {
      dispatch(setUserPlanSnapshot(undefined));
    };
  }, [userId]);

  const items: TableItem<UserPlanSnapshotDto>[] = useMemo(() => {
    return [
      {
        label: "Name",
        render: (x) => <>{x.tariff.name}</>,
      },
      {
        label: "Price",
        render: (x) => <>{centsToUsd(x.tariff.price)}</>,
      },
      {
        label: "Storage, TB",
        render: (x) => <>{prettySize(x.tariff.storage_size)}</>,
      },
      {
        label: "Status",
        render: (x) => (
          <span className={x.active ? styles.active : styles.notActive}>{x.active ? "Active" : "Not active"}</span>
        ),
      },
      {
        label: "Until the end, days",
        render: (x) => {
          const days = differenceInCalendarDays(x.end_at, new Date());
          return <>{days > 0 ? days : 0}</>;
        },
      },
    ];
  }, [userPlanSnapshot]);
  return (
    <div>
      <div className={styles.userTariffsHistoryHead}>
        <Heading variant="h2">Tariffs</Heading>
      </div>
      {userPlanSnapshot && (
        <>
          <CommonTable
            className={styles.userTariffsTable}
            loading={loading}
            items={items}
            idKey="id"
            values={userPlanSnapshot.items}
          />
          {userPlanSnapshot?.pagesCount > 1 && (
            <div className={styles.pagination}>
              <PerPageSwitcher
                value={userPlanSnapshot.perPage}
                onChange={(x) => dispatch(getUserTariffsHistory({ id: userId, query: { perPage: x } }))}
              />
              <PageSwitch
                onChange={(page) => dispatch(getUserTariffsHistory({ id: userId, query: { page } }))}
                pageInfo={{
                  totalCount: userPlanSnapshot.totalCount,
                  page: userPlanSnapshot.page ?? 1,
                  perPage: userPlanSnapshot.perPage ?? 1,
                  pagesCount: userPlanSnapshot.pagesCount,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
