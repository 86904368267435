import { authClient } from "helpers/HttpClient";
import { all, put } from "redux-saga/effects";
import { setCheckedIsLoggedIn, setUser } from "redux/user/userSlice";
import { UserProfile } from "shared/src/models/UserModel";

export function* checkIsLoggedInSaga() {
  try {
    const user: UserProfile = yield authClient.apiGet({ url: "/me" });
    yield all([put(setUser(user)), put(setCheckedIsLoggedIn())]);
  } catch (e) {
    yield put(setCheckedIsLoggedIn());
    console.error(e);
  }
}
