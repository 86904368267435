import { StripeCard } from "./StripeCard";
import { SubPlanEnum } from "./SubPlan";
import { TariffDto } from "./TariffDto";
import { GetTariffUserPlanTO } from "./TariffUserPlan";

export enum BillingHistoryStatusEnum {
  success = "success",
  pending = "pending",
  failed = "failed",
}

export enum BillingHistoryGroupEnum {
  all = "all",
  success = "success",
  pending = "pending",
  failed = "failed",
}

export enum BillingHistoryTypeEnum {
  card_validation = "card_validation",
  subscription = "subscription",
  other = "other",
}

export type BillingHistoryTransactionType = {
  id: string;
  userId: string;
  cardId: string;
  userPlanId: SubPlanEnum;
  paymentProvider: string;
  paymentPayload?: {
    paymentIntentId?: string;
    newPaymentIntentId?: string;
  };
  errors: any;
  status: BillingHistoryStatusEnum;
  type: BillingHistoryTypeEnum;
  amount: number;
  createdAt: string;
  invoiceId: string;
  totalCount: number;
  successCount: number;
  pendingCount: number;
  failedCount: number;
  downloadPdfLink: string;
  userPlan: GetTariffUserPlanTO;
  userCard: StripeCard;
  tariff: TariffDto;
};

export type BillingHistoryPagination = {
  last?: string | number;
  page?: number;
  perPage?: number;
  group?: BillingHistoryGroupEnum;
  id?: number;
};

export type BillingHistoryTO = BillingHistoryPagination & {
  pagesCount: number;
  totalCount: number;
  successCount: number;
  pendingCount: number;
  failedCount: number;
  items: BillingHistoryTransactionType[];
};
