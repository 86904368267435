import { channelsApi } from "api/ChannelsApi";
import { ChannelsTypeList, GetChannelsUsersResponseDto } from "models/Channels";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { setAllSupervisors } from "redux/channels/channelsSlice";
import { parseError } from "shared/src/helpers/errorHandler";

export function* getAllSupervisorsSaga() {
  try {
    const resp: GetChannelsUsersResponseDto = yield call(
      [channelsApi, channelsApi.getChannelsList],
      ChannelsTypeList.supervisor,
      {
        page: 1,
        perPage: 100,
      }
    );
    const supervisors = resp.items.map((item) => ({
      id: item.id,
      email: item.email,
    }));
    yield put(setAllSupervisors(supervisors));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
