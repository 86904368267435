import { usersPageApi } from "api/UsersPageApi";
import { EditUserForm } from "components/EditUserForm/EditUserForm";
import { UserTariffsHistory } from "components/UserTariffsHistory/UserTariffsHistory";
import { Bin, Db, Folder, GoogleDocs } from "iconoir-react";
import { AccessKeysBlock } from "pages/UserInfo/AccessKeysBlock";
import { PaymentMethod } from "pages/UserInfo/PaymentMethod";
import { ProfileOverview } from "pages/UserInfo/ProfileOverview";
import { ExtendTrialModal } from "pages/UsersPage/ExtendTrialModal";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectUserPageCards,
  selectUserPageUserInfo,
  selectUserStoredFiles,
  selectUserTickets,
} from "redux/userPage/selectors/selectUserPage";
import {
  deleteUser,
  extendUserTrialAction,
  getUserPageCards,
  getUserPageStoredFiles,
  getUserPageTickets,
  getUserPageUserInfo,
  updateUserPageAction,
} from "redux/userPage/userPageActions";
import { clearCacheUserPage } from "redux/userPage/userPageSlice";
import { ChartStorageUsage } from "shared/src/components/Charts/ChartStorageUsage";
import DashboardCard from "shared/src/components/DashboardCard/DashboardCard";
import { Heading } from "shared/src/components/Heading/Heading";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { TicketsTable } from "shared/src/components/TicketsTable/TicketsTable";
import { fullName, prettySize } from "shared/src/helpers/utils";
import { httpFormatDate } from "shared/src/http";
import { BandwidthAnalyticsRecord, StorageUsageAnalyticsRecord } from "shared/src/models/Statistics.dto";
import { BillingHistory } from "./BillingHistory";
import { DeleteUserModal } from "./DeleteUserModal";
import { FreezeUserModal } from "./FreezeUserModal";
import styles from "./userInfo.module.scss";

const from = new Date(new Date().setMonth(new Date().getMonth() - 1));
const to = new Date(new Date().setHours(23, 59));

type GetFnAgs = {
  from?: Date;
  to?: Date;
  callback?: (...args: any[]) => void;
};

enum ModalEnum {
  freeze = "freeze",
  delete = "delete",
  extendTrial = "extendTrial",
}

export const UserInfo: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [modalOpen, setModalOpen] = useState<ModalEnum | undefined>(undefined);
  const [editUserFormOpen, setEditUserFormOpen] = useState(false);
  const dispatch = useDispatch();

  const [lui, userInfo2] = useSelector(selectUserPageUserInfo);
  const [lsf, storedFiles] = useSelector(selectUserStoredFiles);
  const [lt, tickets] = useSelector(selectUserTickets);
  const [lc, cards] = useSelector(selectUserPageCards);

  const [analytics, setAnalytics] = useState<{
    BandwidthAnalytics: BandwidthAnalyticsRecord[];
    StorageUsageAnalytics: StorageUsageAnalyticsRecord[];
  }>({ BandwidthAnalytics: [], StorageUsageAnalytics: [] });

  const getChartsInfo = useCallback(async ({ from, to }: GetFnAgs) => {
    const { BandwidthAnalytics } = await usersPageApi.getBandiwthStatistic(id!, {
      toDate: httpFormatDate(to),
      fromDate: httpFormatDate(from),
    });
    const { StorageUsageAnalytics } = await usersPageApi.getStorageStatistic(id!, {
      toDate: httpFormatDate(to),
      fromDate: httpFormatDate(from),
    });

    setAnalytics({
      BandwidthAnalytics: BandwidthAnalytics.Records,
      StorageUsageAnalytics: StorageUsageAnalytics.Records,
    });
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUserPageUserInfo({ id }));
      dispatch(getUserPageStoredFiles({ id }));
      dispatch(getUserPageTickets({ id }));
      dispatch(getUserPageCards({ id }));

      getChartsInfo({ from, to });
    }
    return () => {
      dispatch(clearCacheUserPage());
    };
  }, [id]);

  return (
    <div className={styles.content}>
      <div className="page">
        <div className={styles.topBar}>
          <Heading variant="h2">{userInfo2 && fullName(userInfo2)}</Heading>
          <div className={styles.topBarActions}>
            <PbButton onClick={() => setModalOpen(ModalEnum.extendTrial)} size="lg" color="success">
              Extend the trial
            </PbButton>
            <PbButton onClick={() => setModalOpen(ModalEnum.freeze)} size="lg" color="primary">
              Freeze profile
            </PbButton>
            <PbButton onClick={() => setModalOpen(ModalEnum.delete)} size="lg" color="danger">
              Delete profile
            </PbButton>
          </div>
        </div>
        <div className={styles.statisticsCard}>
          {storedFiles && (
            <DashboardCard title="Buckets" number={storedFiles.bucketsCount} icon={<Bin color="#41A8ED" />} />
          )}
          {storedFiles && (
            <DashboardCard title="Folder" number={storedFiles.foldersCount} icon={<Folder color="#41A8ED" />} />
          )}
          {storedFiles && (
            <DashboardCard title="Files" number={storedFiles.filesCount} icon={<GoogleDocs color="#41A8ED" />} />
          )}
          {storedFiles && (
            <DashboardCard title="Storage" number={prettySize(storedFiles.filesSize)} icon={<Db color="#41A8ED" />} />
          )}
        </div>
        {userInfo2 && (
          <ProfileOverview
            name={userInfo2.name}
            lastName={userInfo2.lastName}
            status={userInfo2.status}
            email={userInfo2.email}
            createdAt={userInfo2.createdAt}
            openEdit={setEditUserFormOpen}
            company={userInfo2.company}
          />
        )}
        <div className={styles.chartWrapper}>
          <ChartStorageUsage
            tabsParent={0}
            initialFromTo={{ fromInput: from, toInput: to }}
            dataBandwidth={analytics.BandwidthAnalytics}
            dataStorage={analytics.StorageUsageAnalytics}
            get={getChartsInfo}
          />
        </div>
        <AccessKeysBlock loading={lui} accessKeys={userInfo2?.accessKeys} />
        {id && <UserTariffsHistory userId={id} />}
        <PaymentMethod loading={lc} cards={cards} />
        {id && <BillingHistory userId={id} />}
        <TicketsTable tickets={tickets} loading={lt} />

        {userInfo2 && (
          <>
            <FreezeUserModal
              user={userInfo2}
              active={modalOpen === ModalEnum.freeze}
              onClose={() => setModalOpen(undefined)}
            />
            <EditUserForm
              onSubmit={(dto) =>
                dispatch(updateUserPageAction({ userDto: dto, onSuccess: () => setEditUserFormOpen(false) }))
              }
              user={userInfo2}
              active={editUserFormOpen}
              onClose={() => setEditUserFormOpen(false)}
            />
            <ExtendTrialModal
              user={userInfo2}
              active={modalOpen === ModalEnum.extendTrial}
              onClose={() => setModalOpen(undefined)}
              onSubmit={(days) =>
                dispatch(
                  extendUserTrialAction({
                    userId: userInfo2.id,
                    days,
                    onSuccess: () => setModalOpen(undefined),
                    actionPage: "user-page",
                  })
                )
              }
            />
            <DeleteUserModal
              user={userInfo2}
              active={modalOpen === ModalEnum.delete}
              onClose={() => setModalOpen(undefined)}
              onSubmit={(reason) => dispatch(deleteUser({ actionPage: "user-page", id: userInfo2.id, reason }))}
            />
          </>
        )}
      </div>
    </div>
  );
};
