import React from "react";
import styles from "./form.module.scss";

type FormProps = {
  title?: string;
  width?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const Form: React.FC<React.PropsWithChildren<FormProps>> = ({ children, width = "478px", title, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} style={{ maxWidth: width }} className={styles.form}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </form>
  );
};
