import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserForTestCronAdminPage } from "api/CronTestsApi";

export type CronTestsState = {
  users: UserForTestCronAdminPage[];
  loading: boolean;
};

const initialState: CronTestsState = {
  users: [],
  loading: false,
};

const cronTestsSlice = createSlice({
  name: "cronTestsSlice",
  initialState,
  reducers: {
    setLoadingCronTestsAction: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setUsersForTestCron: (state, action: PayloadAction<UserForTestCronAdminPage[]>) => {
      return { ...state, loading: false, users: action.payload };
    },
  },
});

export const { setLoadingCronTestsAction, setUsersForTestCron } = cronTestsSlice.actions;
export const cronTestsReducer = cronTestsSlice.reducer;
