import { takeLatest } from "redux-saga/effects";
import {
  clearUsersForTestCronAction,
  getUsersForTestCronAction,
  initUsersForTestCronAction,
  triggerCronAction,
} from "redux/cronTests/cronTestsActions";
import { clearUsersForTestCronSage } from "redux/cronTests/sagas/clearUsersForTestCronSage";
import { getUsersForTestCronSage } from "redux/cronTests/sagas/getUsersForTestCronSage";
import { initUsersForTestCronSage } from "redux/cronTests/sagas/initUsersForTestCronSage";
import { triggerCronSaga } from "redux/cronTests/sagas/triggerCronSaga";

export function* cronTestsPageSaga() {
  yield takeLatest(getUsersForTestCronAction.type, getUsersForTestCronSage);
  yield takeLatest(initUsersForTestCronAction.type, initUsersForTestCronSage);
  yield takeLatest(clearUsersForTestCronAction.type, clearUsersForTestCronSage);
  yield takeLatest(triggerCronAction.type, triggerCronSaga);
}
