import { takeLatest } from "redux-saga/effects";
import { checkIsLoggedInSaga } from "redux/user/sagas/checkIsLoggedInSaga";
import { loginSaga } from "redux/user/sagas/loginSaga";
import { checkIsLoggedIn, login, logOut } from "redux/user/userActions";
import { logOutSaga } from "./sagas/logOutSaga";

export function* userSaga() {
  yield takeLatest(login.type, loginSaga);
  yield takeLatest(checkIsLoggedIn.type, checkIsLoggedInSaga);
  yield takeLatest(logOut.type, logOutSaga);
}
