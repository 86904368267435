import { createAction } from "@reduxjs/toolkit";
import { GetUsersQuery } from "api/UsersPageApi";
import { CreateUserFormType } from "components/AddNewAccount/AddNewAccount";
import { CreateInvoiceDto, PartnerInvoiceDto } from "models/CreateInvoice.dto";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { BillingHistoryDto } from "shared/src/models/BillingHistory.model";
import { Callback } from "shared/src/models/Callback";
import { PaginationChangePayload, PaginationQueryParams } from "shared/src/models/Pagination";
import { UpdateUserDto } from "shared/src/models/UserModel";

export type GetUsersActionPayload = {
  pagination?: PaginationChangePayload;
  querySort?: QuerySort;
  search?: string;
  group?: GetUsersQuery["group"];
};

export type CreateNewUserActionType = CreateUserFormType & { onSuccess?: () => void };
export type UpdateUserActionPayload = { userDto: UpdateUserDto; onSuccess: () => void };
export type ExtendUserTrial = {
  userId: string;
  days: number;
  onSuccess?: () => void;
  actionPage: "table" | "user-page";
};
export type GetUserTariffsHistoryPayload = { id: string; query: PaginationQueryParams } & Callback;
export type CreateInvoiceActionType = {
  userId: string;
  data: CreateInvoiceDto | PartnerInvoiceDto;
  onSuccess?: (dto: BillingHistoryDto) => void;
};

export const getUsersAction = createAction<GetUsersActionPayload | undefined>("getUsersAction");
export const createNewUserAction = createAction<CreateNewUserActionType>("createNewUser");
export const updateUserTableAction = createAction<UpdateUserActionPayload>("updateUserTableAction");
export const extendUserTrialAction = createAction<ExtendUserTrial>("extendUserTrialAction");
export const createInvoiceAction = createAction<CreateInvoiceActionType>("createInvoiceAction");
export const createInvoicePartnerAction = createAction<CreateInvoiceActionType>("createInvoicePartnerAction");
export const freezeUser = createAction<{ id: string }>("freezeUser");
export const deleteUser =
  createAction<{ id: string; reason?: string; actionPage: "table" | "user-page" }>("deleteUser");
export const getMinimalAllCompanies = createAction("getMinimalAllCompanies");
export const addCompanyToUser = createAction<{ companyId: string; userId: string }>("addCompanyToUser");
export const clearUsersTableCache = createAction("clearUsersTableCache");

// USER PAGE ACTIONS
export const getUserPageUserInfo = createAction<{ id: string }>("getUserPageUserInfo");
export const getUserPageStoredFiles = createAction<{ id: string }>("getUserPageStoredFiles");
export const getUserPageTickets = createAction<{ id: string }>("getUserPageTickets");
export const getUserPageCards = createAction<{ id: string }>("getUserPageCards");
export const getUserPageBillingHistory =
  createAction<{ id: string; query?: PaginationQueryParams }>("getUserPageBillingHistory");
export const getUserTariffsHistory = createAction<GetUserTariffsHistoryPayload>("getUserTariffsHistory");
export const updateUserPageAction = createAction<UpdateUserActionPayload>("updateUserPageAction");
