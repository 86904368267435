import { FC } from "react";
import { Control, Controller, FieldPathValue, FieldValues, Path } from "react-hook-form";
import { NestedByType } from "../../helpers/ts-helpers";
import { InputDate } from "../InputDate/InputDate";
import { TextInput } from "../TextInput/TextInput";

export const getError = (formState: { errors: Record<string, any> }, name: string) => {
  const tokens = name.split(".");

  let value = formState.errors;
  for (const token of tokens) {
    if (value[token]) value = value[token];
  }

  return value && value.message ? (value.message as string) : undefined;
};

type CtrlBase<TData extends FieldValues, TValue = never> = {
  control: Control<TData>;
  name: NestedByType<TData, TValue>;
};

type CntrlWrapProps<TData extends FieldValues, TValue> = CtrlBase<TData, TValue> & {
  render: (value: TValue, onChange: (value: TValue) => void, error?: string) => JSX.Element;
};

export type CtrlProps<TData, TValue, Other> = Omit<
  CtrlBase<TData extends FieldValues ? TData : FieldValues, TValue>,
  keyof Other
> &
  Other;

export function CntrlWrap<TData extends FieldValues, TValue>(props: CntrlWrapProps<TData, TValue>) {
  const { name, control, render } = props;

  return (
    <Controller
      name={name as unknown as Path<TData>}
      control={control}
      render={({ field: { value, onChange }, formState }) => {
        const error = getError(formState, name as string);
        return render(value as TValue, onChange, error);
      }}
    />
  );
}

export const makeCtrl = <T extends Pick<T, "value" | "onChange"> & { error?: string }>(
  Component: FC<T>,
  options: { errorKey?: string } = {}
) => {
  // react-hook-form has FieldPathByValue but it slow, so we use NestedByType
  const Wrapped = <D extends FieldValues, P extends FieldPathValue<D, Path<D>>>(
    props: Omit<T, "value" | "onChange" | "error"> & { control: Control<D>; name: P }
  ) => {
    const { control, name, ...rest } = props;
    const errorKey = options.errorKey ? `${name}.${options.errorKey}` : (name as string).toString();

    return (
      <Controller
        control={control}
        // @ts-expect-error because of comment above
        name={name}
        render={({ field: { value, onChange }, formState }) => {
          const error = getError(formState, errorKey.toString());
          const props = { ...rest, value, onChange, error } as unknown as T;
          return <Component {...props} />;
        }}
      />
    );
  };

  return Wrapped;
};

export const CntrText = makeCtrl(TextInput);
export const CntrDate = makeCtrl(InputDate);
