import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TariffCatalogDto } from "shared/src/models/TariffCatalogDto";

type CatalogState = {
  tariffCatalog: TariffCatalogDto[];
  dataVolumesCatalog: string[];
};

const initialState: CatalogState = {
  tariffCatalog: [],
  dataVolumesCatalog: [],
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setTariffCatalog(state, action: PayloadAction<TariffCatalogDto[]>) {
      return {
        ...state,
        tariffCatalog: action.payload,
      };
    },
    setDataVolumes(state, action: PayloadAction<string[]>) {
      return { ...state, dataVolumesCatalog: action.payload };
    },
  },
});

export const catalogReducer = catalogSlice.reducer;
export const { setTariffCatalog, setDataVolumes } = catalogSlice.actions;
