import { ChannelsBar } from "components/ChannelsBar/ChannelsBar";
import { format } from "date-fns";
import { CreateInvoice } from "features/CreateInvoice/CreateInvoice";
import { EditPencil, MoneySquare } from "iconoir-react";
import { isNil } from "lodash";
import { ChannelsTypeList, ChannelsUser } from "models/Channels";
import { CreateInvoiceDto, PartnerInvoiceDto } from "models/CreateInvoice.dto";
import { InvoiceType } from "models/InvoiceType";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSupervisorsOrPartnersList } from "redux/channels/channelsActions";
import { setQuerySortChannel, setSearchChannel } from "redux/channels/channelsSlice";
import { selectChannelsPageState } from "redux/channels/selectors/selectChannelsState";
import { createInvoicePartnerAction } from "redux/userPage/userPageActions";
import { CommonTable, QuerySort, TableItem } from "shared/src/components/CommonTable/CommonTable";
import DropdownVertical, { DropItem } from "shared/src/components/DropdownVertical/DropdownVertical";
import { Heading } from "shared/src/components/Heading/Heading";
import { PageSwitch } from "shared/src/components/PageSwitch/PageSwitch";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { PerPageSwitcher } from "shared/src/components/PerPageSwitcher/PerPageSwitcher";
import { SearchInput } from "shared/src/components/SearchInput/SearchInput";
import { OptionType, SelectField } from "shared/src/components/Select/SelectField";
import styles from "./Channels.module.scss";

const safeDateFormat = (date?: Date, template = "P") => {
  if (isNil(date)) return;
  return format(date, template);
};

const channelsListTypeOptions: Array<OptionType<ChannelsTypeList>> = [
  { label: "Supervisor", value: ChannelsTypeList.supervisor },
  { label: "Partner", value: ChannelsTypeList.partner },
];
export const Channels: React.FC = () => {
  const [channelsListType, setChannelsListType] = useState(ChannelsTypeList.supervisor);
  const [channelBarOpen, setChannelBarOpen] = useState(false);
  const [channelForEdit, setChannelForEdit] = useState<ChannelsUser | undefined>(undefined);
  const [invoiceDrawer, setInvoiceDrawer] = useState<ChannelsUser | undefined>(undefined);
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { users, loading, pagination, querySort, search } = useSelector(selectChannelsPageState);
  const getList = (...args: Parameters<typeof getSupervisorsOrPartnersList>) =>
    dispatch(getSupervisorsOrPartnersList(...args));

  useEffect(() => {
    dispatch(getList({ querySort, search, pagination: { page: 1 }, type: channelsListType }));
  }, [querySort, search, pagination.perPage, channelsListType]);

  const editChannel = (channel: ChannelsUser) => {
    setChannelForEdit(channel);
    setChannelBarOpen(true);
  };

  const closeChannelModal = () => {
    setChannelForEdit(undefined);
    setChannelBarOpen(false);
  };

  const setSearch = (value: string) => dispatch(setSearchChannel(value));
  const setQuerySort = (querySort: QuerySort) => dispatch(setQuerySortChannel(querySort));

  const PaginationComponent = () => (
    <div className={styles.pagination}>
      <PerPageSwitcher
        value={pagination.perPage}
        onChange={(x) => getList({ pagination: { perPage: x }, type: channelsListType })}
      />
      <PageSwitch
        onChange={(page) => getList({ pagination: { page }, type: channelsListType })}
        pageInfo={pagination}
      />
    </div>
  );
  const MenuItems = useCallback(
    (channel: ChannelsUser) => {
      const items: DropItem[] = [
        {
          icon: <EditPencil width={20} height={20} color="#6E7E9D" />,
          text: t("Edit"),
          isAccent: false,
          onClick: () => editChannel(channel),
          section: 1,
        },
      ];
      if (channelsListType === ChannelsTypeList.partner) {
        items.push({
          icon: <MoneySquare width={20} height={20} color="#6E7E9D" />,
          text: "Create Invoice",
          isAccent: false,
          onClick: () => setInvoiceDrawer(channel),
          section: 1,
        });
      }
      return items;
    },
    [users]
  );
  const items: TableItem<typeof users[0]>[] = [
    {
      label: "Name",
      sortKey: "name",
      render: ({ name }) => name,
    },
    {
      label: "Email",
      render: ({ email }) => email,
    },
    {
      label: "Creation date",
      sortKey: "created_at",
      render: ({ created_at }) => safeDateFormat(created_at),
    },
    {
      label: "Master application key",
      render: ({ master_application_key }) => master_application_key,
    },
    {
      label: "Actions",
      width: 45,
      render: (channel) => <DropdownVertical noPaddingIcon dropdowns={MenuItems(channel)} />,
    },
  ];

  const onCreateInvoice = (data: CreateInvoiceDto | PartnerInvoiceDto) => {
    if (invoiceDrawer?.id) {
      dispatch(
        createInvoicePartnerAction({
          data,
          onSuccess: () => {
            setInvoiceDrawer(undefined);
          },
          userId: invoiceDrawer.id,
        })
      );
    }
  };

  return (
    <div className={styles.content}>
      <div className="page">
        <div className={styles.topBar}>
          <Heading className={styles.topHeading} variant="h2">
            Users
          </Heading>
          <SearchInput
            defaultValue={search}
            placeholder="Enter name or email"
            onChange={setSearch}
            className={styles.searchInput}
          />
          <PbButton onClick={() => setChannelBarOpen(true)} size="lg" color="success">
            Create a channel
          </PbButton>
        </div>

        <div>
          <div className={styles.topPagination}>
            <div className={styles.switcherWrapper}>
              <SelectField
                value={channelsListType}
                className={styles.selectChannelsType}
                onChange={(value) => value && setChannelsListType(value)}
                options={channelsListTypeOptions}
              />
            </div>

            <PaginationComponent />
          </div>
          <CommonTable
            idKey="id"
            onSortChange={setQuerySort}
            sortInfo={querySort}
            items={items}
            values={users}
            loading={loading}
          />
          <div className={styles.bottomPagination}>
            <PaginationComponent />
          </div>
        </div>
        {channelBarOpen && (
          <ChannelsBar
            type={channelsListType}
            originChannel={channelForEdit}
            open={channelBarOpen}
            onClose={closeChannelModal}
          />
        )}
        {!!invoiceDrawer && (
          <CreateInvoice
            pending={false}
            invoiceType={InvoiceType.partner}
            active={!!invoiceDrawer}
            onClose={() => setInvoiceDrawer(undefined)}
            userId={invoiceDrawer.id}
            onSubmit={onCreateInvoice}
          />
        )}
      </div>
    </div>
  );
};
