import { PayloadAction } from "@reduxjs/toolkit";
import { usersPageApi } from "api/UsersPageApi";
import { toast } from "react-toastify";
import { call } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";
import { BillingHistoryDto } from "shared/src/models/BillingHistory.model";
import { CreateInvoiceActionType } from "../userPageActions";

export function* createInvoiceSaga({ payload }: PayloadAction<CreateInvoiceActionType>) {
  const { onSuccess, data, userId } = payload;

  try {
    const res: BillingHistoryDto = yield call([usersPageApi, usersPageApi.createInvoice], userId, data);
    onSuccess?.(res);
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
  }
}
