export type PaginationQueryParams = {
  page?: number;
  perPage?: number;
};

export type PaginationInfo = {
  totalCount: number;
  page: number;
  perPage: number;
  pagesCount: number;
};

export type Pagination<T> = {
  items: T[];
} & PaginationInfo;

export const getPaginationFromDto = <T extends Pagination<unknown>>(pagenableDto: T): PaginationInfo => {
  const { totalCount, page, perPage, pagesCount } = pagenableDto;
  return { totalCount, page, perPage, pagesCount };
};

export const defaultPaginationInfo: PaginationInfo = {
  totalCount: 0,
  pagesCount: 0,
  page: 0,
  perPage: 15,
};

export type PaginationChangePayload = Partial<Pick<PaginationInfo, "page" | "perPage">>;
