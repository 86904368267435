import { RefObject, useCallback, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent;

type SingleElement = RefObject<HTMLElement>;
type ArrayElements = Array<SingleElement>;
type Args = SingleElement | ArrayElements;

const isSingleElement = (x: Args): x is SingleElement => {
  return !Array.isArray(x) ? true : false;
};

export const useOnClickOutside = (elementRef: Args, fn: (event: AnyEvent) => void): void => {
  const handler = useCallback(
    (event: AnyEvent) => {
      const isSingle = isSingleElement(elementRef);

      if (isSingle) {
        if (!elementRef?.current?.contains(event.target as Node)) {
          fn(event);
        }
      } else {
        const htmlElements = elementRef.reduce((elements, ref) => {
          return ref.current ? [...elements, ref.current] : elements;
        }, [] as HTMLElement[]);

        const isOusideElement = htmlElements.every((x) => !x.contains(event.target as Node));
        if (isOusideElement) {
          fn(event);
        }
      }
    },
    [fn]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  });
};
