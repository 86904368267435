import { CreditCard, Db, GoogleDocs, NoCreditCard, User, WarningTriangleOutline } from "iconoir-react";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectStatisticsCommon } from "redux/statistics/selectors/selectStatisticsCommon";
import { getStatisticsCommon } from "redux/statistics/statisticsActions";
import DashboardCard from "shared/src/components/DashboardCard/DashboardCard";
import styles from "./statistics.module.scss";

export const Statistics: React.FC = () => {
  const dispatch = useDispatch();
  const statisticsCommon = useSelector(selectStatisticsCommon);

  const cards = useMemo(() => {
    if (statisticsCommon) {
      return [
        { title: "Users", number: statisticsCommon.totalUsersCount, icon: <User /> },
        { title: "Files", number: statisticsCommon.storedObjectsCount, icon: <GoogleDocs /> },
        { title: "Storage", number: statisticsCommon.usedStorageReadable, icon: <Db /> },
        {
          title: "Active Tariffs ",
          number: statisticsCommon.activeTariffs,
          icon: <CreditCard color="#47C281" />,
          color: "#47C281",
        },
        {
          title: "Unpaid Tariffs",
          number: statisticsCommon.unpaidTariffs,
          icon: <NoCreditCard color="#F0B90B" />,
          color: "#F0B90B",
        },
        {
          title: "Suspended Tariffs",
          number: statisticsCommon.suspendedTariffs,
          icon: <WarningTriangleOutline color="#FE5F55" />,
          color: "#FE5F55",
        },
      ];
    } else {
      return [];
    }
  }, [statisticsCommon]);

  useEffect(() => {
    if (!statisticsCommon) {
      dispatch(getStatisticsCommon());
    }
  }, [statisticsCommon, dispatch]);

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.headTitle}>Statistics</div>
        </div>
        <div className={styles.statisticsCard}>
          {cards.map((card, idx) => (
            <DashboardCard key={idx} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};
