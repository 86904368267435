import { PayloadAction } from "@reduxjs/toolkit";
import { usersPageApi } from "api/UsersPageApi";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";
import { BillingHistoryTO } from "shared/src/models/BillingHistory";
import { Pagination, PaginationQueryParams } from "shared/src/models/Pagination";
import { UserPlanSnapshotDto } from "shared/src/models/UserPlanSnapshotDto";
import { GetUserTariffsHistoryPayload } from "../userPageActions";
import {
  setLoadingUserPageState,
  setUserPageBillingHistory,
  setUserPageCards,
  setUserPageInfo,
  setUserPageStoredFiles,
  setUserPageTickets,
  setUserPlanSnapshot,
} from "../userPageSlice";

export function* getUserPageUserInfoSaga({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield put(setLoadingUserPageState({ key: "info", loading: true }));
    const userInfo = yield call([usersPageApi, usersPageApi.getUserInfo2], payload.id);
    yield put(setUserPageInfo(userInfo));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    yield put(setLoadingUserPageState({ key: "info", loading: false }));
    console.error(e);
  }
}

export function* getUserPageStoredFilesSaga({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield put(setLoadingUserPageState({ key: "storedFilesInfo", loading: true }));
    const storedFiles = yield call([usersPageApi, usersPageApi.getStoredFilesInfo], payload.id);
    yield put(setUserPageStoredFiles(storedFiles));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    yield put(setLoadingUserPageState({ key: "storedFilesInfo", loading: false }));
    console.error(e);
  }
}

export function* getUserPageTicketsSaga({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield put(setLoadingUserPageState({ key: "tickets", loading: true }));
    const tickets = yield call([usersPageApi, usersPageApi.getUserTickets], payload.id);
    yield put(setUserPageTickets(tickets));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    yield put(setLoadingUserPageState({ key: "tickets", loading: false }));
    console.error(e);
  }
}

export function* getUserPageCardsSaga({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield put(setLoadingUserPageState({ key: "cards", loading: true }));
    const cards = yield call([usersPageApi, usersPageApi.getUserCards], payload.id);
    yield put(setUserPageCards(cards));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    yield put(setLoadingUserPageState({ key: "cards", loading: false }));
    console.error(e);
  }
}

export function* getUserPageBillingHistorySaga({
  payload,
}: PayloadAction<{ id: string; query: PaginationQueryParams }>) {
  try {
    yield put(setLoadingUserPageState({ key: "billingHistory", loading: true }));
    const billingHistory: BillingHistoryTO = yield call(
      [usersPageApi, usersPageApi.getUserBillingHistory],
      payload.id,
      payload.query
    );
    yield put(setUserPageBillingHistory(billingHistory));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    yield put(setLoadingUserPageState({ key: "billingHistory", loading: false }));
    console.error(e);
  }
}

export function* getUserTariffsHistorySaga({ payload }: PayloadAction<GetUserTariffsHistoryPayload>) {
  try {
    yield put(setLoadingUserPageState({ key: "userPlanSnapshot", loading: true }));
    const userPlanSnapshot: Pagination<UserPlanSnapshotDto> = yield call(
      [usersPageApi, usersPageApi.getUserTariffsHistory],
      payload.id,
      payload.query
    );
    yield put(setUserPlanSnapshot(userPlanSnapshot));
    payload?.callback?.();
  } catch (e) {
    yield put(setLoadingUserPageState({ key: "userPlanSnapshot", loading: false }));
    yield call([toast, toast.error], parseError(e)?.message);
  }
}
