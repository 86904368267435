import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile } from "shared/src/models/UserModel";

type UserState = {
  userProfile?: UserProfile;
  checkedIsLoggedIn: boolean;
};

const initialState: UserState = {
  userProfile: undefined,
  checkedIsLoggedIn: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserProfile>) {
      return {
        ...state,
        userProfile: action.payload,
      };
    },
    setCheckedIsLoggedIn(state) {
      return {
        ...state,
        checkedIsLoggedIn: true,
      };
    },
    setInitialState(state) {
      return {
        ...state,
        ...initialState,
        checkedIsLoggedIn: true,
      };
    },
  },
});

export const userReducer = userSlice.reducer;

export const { setUser, setCheckedIsLoggedIn, setInitialState } = userSlice.actions;
