import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUsersPageBtnLoading } from "redux/userPage/selectors/selectUsersState";
import { freezeUser } from "redux/userPage/userPageActions";
import { CoreModal } from "shared/src/components/Modal/Modal";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { UserProfile } from "shared/src/models/UserModel";
import styles from "./actionUserModals.module.scss";

type ExtendTrialModalProps = {
  active: boolean;
  onClose: () => void;
  user: UserProfile;
};

export const FreezeUserModal: React.FC<ExtendTrialModalProps> = (props) => {
  const { active = false, onClose, user } = props;
  const loading = useSelector(selectUsersPageBtnLoading);
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(
      freezeUser({
        id: user.id,
      })
    );
    onClose();
  };

  return (
    <CoreModal
      footer={
        <div className={styles.footer}>
          <PbButton onClick={onClose} color="secondary-bordered" className={styles.btn}>
            Cancel
          </PbButton>
          <PbButton onClick={submit} loading={loading} disabled={loading} color="primary" className={styles.btn}>
            Freeze account
          </PbButton>
        </div>
      }
      className={styles.modal}
      title="Freeze account"
      visible={active}
      onClose={onClose}
      closeOutClick
    >
      <p className={styles.text}>
        Are you sure you want to freeze{" "}
        <span className={styles.name}>
          {user.name} {user.lastName}
        </span>{" "}
        account?
      </p>
    </CoreModal>
  );
};
