import { takeLatest } from "redux-saga/effects";
import {
  createPartner,
  createSupervisor,
  editPartner,
  editSupervisor,
  getAllSupervisors,
  getSupervisorsOrPartnersList,
  reGenerateMasterKey,
} from "redux/channels/channelsActions";
import { createPartnerSaga } from "redux/channels/sagas/createPartnerSaga";
import { createSupervisorSaga } from "redux/channels/sagas/createSupervisorSaga";
import { editPartnerSaga } from "redux/channels/sagas/editPartnerSaga";
import { editSupervisorSaga } from "redux/channels/sagas/editSupervisorSaga";
import { getAllSupervisorsSaga } from "redux/channels/sagas/getAllSupervisorsSaga";
import { getSupervisorsOrPartnersListSaga } from "redux/channels/sagas/getSupervisorsOrPartnersListSaga";
import { reGenerateMasterKeySaga } from "redux/channels/sagas/reGenerateMasterKeySaga";

export function* channelsSaga() {
  yield takeLatest(getSupervisorsOrPartnersList.type, getSupervisorsOrPartnersListSaga);
  yield takeLatest(createSupervisor.type, createSupervisorSaga);
  yield takeLatest(createPartner.type, createPartnerSaga);
  yield takeLatest(getAllSupervisors.type, getAllSupervisorsSaga);
  yield takeLatest(editSupervisor.type, editSupervisorSaga);
  yield takeLatest(editPartner.type, editPartnerSaga);
  yield takeLatest(reGenerateMasterKey.type, reGenerateMasterKeySaga);
}
