import { createAction } from "@reduxjs/toolkit";
import { CreateTariff } from "models/TariffDto";
import { QuerySort } from "shared/src/components/CommonTable/CommonTable";
import { Callback } from "shared/src/models/Callback";
import { PaginationChangePayload } from "shared/src/models/Pagination";

export type GetCustomTariffsActionPayload = {
  pagination?: PaginationChangePayload;
  querySort?: QuerySort;
};

export const getCustomTariffsAction = createAction<GetCustomTariffsActionPayload | undefined>("getCustomTariffsAction");
export const getSimpleUserListAction = createAction("getSimpleUserList");
export const createNewTariffAction = createAction<CreateTariff & Callback>("createNewTariff");
