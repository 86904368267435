import { yupResolver } from "@hookform/resolvers/yup";
import styles from "components/ChannelsBar/channelsBar.module.scss";
import { channelValidateSchema } from "components/ChannelsBar/channelValidateSchema";
import { ChannelsTypeList, ChannelsUser, SupervisorForm } from "models/Channels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createPartner,
  createSupervisor,
  editPartner,
  editSupervisor,
  getAllSupervisors,
  reGenerateMasterKey,
} from "redux/channels/channelsActions";
import { selectAllSimpleSupervisor } from "redux/channels/selectors/selectAllSimpleSupervisor";
import { selectMasterKeyByChannelId } from "redux/channels/selectors/selectMasterKeyByChannelId";
import { ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { RightDrawer } from "shared/src/components/RightDrawer/RightDrawer";
import { OptionType, SelectField } from "shared/src/components/Select/SelectField";

type ChannelsBarProps = {
  open: boolean;
  onClose: () => void;
  originChannel?: ChannelsUser;
  type: ChannelsTypeList;
};

const channelTypeOptions: Array<OptionType<ChannelsTypeList>> = [
  { label: "Supervisor", value: ChannelsTypeList.supervisor },
  { label: "Partner", value: ChannelsTypeList.partner },
];
export const ChannelsBar: React.FC<ChannelsBarProps> = ({ open, onClose, originChannel, type }) => {
  const title = `${originChannel ? "Edit" : "Create"} a channel`;
  const [channelType, setChannelType] = useState(type);
  const [uuid, setUuid] = useState<string | undefined>(originChannel?.supervisor_id);
  const dispatch = useDispatch();
  const { control, handleSubmit, trigger, getValues, setValue } = useForm<SupervisorForm>({
    mode: "onChange",
    resolver: yupResolver(channelValidateSchema),
    defaultValues: { ...originChannel },
  });
  const simpleSupervisor = useSelector(selectAllSimpleSupervisor);
  const masterKey = useSelector(selectMasterKeyByChannelId(originChannel?.id));
  const onCreate = async () => {
    if (!(await trigger())) {
      return;
    }
    const formData = getValues();
    if (channelType === ChannelsTypeList.supervisor) {
      if (!!originChannel) {
        dispatch(
          editSupervisor({
            ...formData,
            id: originChannel.id,
          })
        );
      } else {
        dispatch(
          createSupervisor({
            email: formData.email,
            name: formData.name,
          })
        );
      }
    } else {
      if (!!originChannel) {
        dispatch(
          editPartner({
            id: originChannel.id,
            email: formData.email,
            name: formData.name,
            supervisor_id: uuid,
          })
        );
      } else {
        dispatch(
          createPartner({
            email: formData.email,
            name: formData.name,
            supervisor_id: uuid,
          })
        );
      }
    }

    onClose();
  };
  const createMasterKey = () => {
    if (originChannel?.id) {
      dispatch(
        reGenerateMasterKey({
          id: originChannel.id,
          type: channelType,
        })
      );
    }
  };

  useEffect(() => {
    if (channelType === ChannelsTypeList.partner) {
      dispatch(getAllSupervisors());
    }
  }, [channelType]);
  useEffect(() => {
    if (masterKey) {
      setValue("master_application_key", masterKey);
    }
  }, [masterKey]);

  return (
    <RightDrawer
      open={open}
      onClose={onClose}
      title={title}
      footer={
        <div className={styles.actions}>
          <PbButton onClick={onClose} className={styles.action} size="md" color="secondary">
            Cancel
          </PbButton>
          <PbButton onClick={handleSubmit(onCreate)} type="submit" className={styles.action} size="md" color="primary">
            {originChannel ? "Save" : "Create"}
          </PbButton>
        </div>
      }
    >
      {!originChannel && (
        <SelectField
          value={channelType}
          label="Status"
          className={styles.field}
          onChange={(value) => value && setChannelType(value)}
          options={channelTypeOptions}
        />
      )}
      <ControlledText required className={styles.field} label="Name" control={control} name="name" type="text" />
      <ControlledText required className={styles.field} label="Email" control={control} name="email" type="text" />
      {originChannel !== undefined && (
        <ControlledText
          required
          disabled
          className={styles.field}
          label="Master_application_key"
          control={control}
          name="master_application_key"
          type="text"
          refreshAction={createMasterKey}
        />
      )}
      {channelType === ChannelsTypeList.partner && (
        <SelectField
          value={uuid}
          label="Select a supervisor (uuid)"
          className={styles.field}
          onChange={(value) => value && setUuid(value)}
          options={simpleSupervisor.map((supervisor) => ({
            label: supervisor.email,
            value: supervisor.id,
          }))}
        />
      )}
    </RightDrawer>
  );
};
