import { PayloadAction } from "@reduxjs/toolkit";
import { usersPageApi } from "api/UsersPageApi";
import { CreateUserFormType } from "components/AddNewAccount/AddNewAccount";
import { CorporateAccountFields, DefaultAccountFields } from "models/AddNewAccount";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";
import { CreateNewUserActionType, getUsersAction } from "../userPageActions";

const toCorporateDto = (x: CreateUserFormType): CorporateAccountFields => {
  return {
    firstName: x.firstName,
    lastName: x.lastName,
    companyName: x.companyName,
    country: x.country,
    dataVolume: x.dataVolume,
    phone: x.phone,
    password: x.password,
    tariffId: x.tariffId,
    email: x.email,
  };
};

const toNewUserDto = (x: CreateUserFormType): DefaultAccountFields => {
  return {
    name: x.name,
    email: x.email,
    password: x.password,
    tariffId: x.tariffId,
  };
};

export function* createNewUserSaga({ payload }: PayloadAction<CreateNewUserActionType>) {
  const { isCorporateClient, onSuccess } = payload;

  try {
    if (isCorporateClient) {
      const dto = toCorporateDto(payload);
      yield call([usersPageApi, usersPageApi.createCorporateUser], dto);
    } else {
      const dto = toNewUserDto(payload);
      yield call([usersPageApi, usersPageApi.createUser], dto);
    }
    onSuccess?.();

    yield call([toast, toast.success], "User successfully created");
    yield put(getUsersAction());
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
