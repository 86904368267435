import { yupResolver } from "@hookform/resolvers/yup";
import styles from "components/AddNewTariff/addNewTariff.module.scss";
import { newTariffFormSchema } from "components/AddNewTariff/newTariffFormSchema";
import _ from "lodash";
import { CreateTariff } from "models/TariffDto";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { MultiValue } from "react-select";
import { createNewTariffAction, getSimpleUserListAction } from "redux/customTariffsPage/customTariffsPageActions";
import { selectLoadingBtnActionState } from "redux/customTariffsPage/selectors/selectCustomTariffsState";
import { selectSimpleUsers } from "redux/customTariffsPage/selectors/selectSimpleUsers";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import { ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { RightDrawer } from "shared/src/components/RightDrawer/RightDrawer";
import { OptionType } from "shared/src/components/Select/SelectField";
import { SelectMulti } from "shared/src/components/SelectMulti/SelectMulti";

type AddNewTariffProps = {
  open: boolean;
  onClose: () => void;
};

type CreateNewTariff = CreateTariff & {
  isTrial: boolean;
};

export const AddNewTariff: React.FC<AddNewTariffProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const simpleUsers = useSelector(selectSimpleUsers);
  const loading = useSelector(selectLoadingBtnActionState);
  const [selectedEmails, setSelectedEmails] = useState<MultiValue<{ label?: string; value?: string }> | undefined>([]);
  const { control, handleSubmit, trigger, getValues, watch, setValue, resetField } = useForm<CreateNewTariff>({
    mode: "onChange",
    resolver: yupResolver(newTariffFormSchema),
    defaultValues: {
      isTrial: true,
      trialDays: 30,
      name: "",
      storage_size: 0,
      bandwidth: 0,
      cost_storage_gb: 0,
      cost_bandwidth_gb: 0,
      trial_storage_limit: 0,
      trial_transfer_limit: 0,
      price: 0,
      description: "",
    },
  });
  const isTrial = watch("isTrial");

  const setDefaultState = () => {
    resetField("isTrial");
    resetField("trialDays");
    resetField("name");
    resetField("storage_size");
    resetField("bandwidth");
    resetField("cost_storage_gb");
    resetField("cost_bandwidth_gb");
    resetField("trial_storage_limit");
    resetField("trial_transfer_limit");
    resetField("price");
    resetField("description");
  };

  const onCreate = async () => {
    if (!(await trigger())) {
      return;
    }
    const formData = getValues();
    let emails: string[] = [];
    if (selectedEmails && selectedEmails?.length > 0) {
      selectedEmails.forEach((email) => {
        if (email.value) {
          emails.push(email.value);
        }
      });
    }
    // convert price > cents it's neccessary to valid payout customer
    formData.price = formData.price * 100;
    dispatch(
      createNewTariffAction({ ..._.omit(formData, "isTrial", isTrial ? "" : "trialDays"), emails, callback: onClose })
    );
  };

  useEffect(() => {
    if (simpleUsers.length === 0) {
      dispatch(getSimpleUserListAction());
    }
    return () => {
      setDefaultState();
    };
  }, []);

  const options: Array<OptionType<string | undefined>> = useMemo(() => {
    return simpleUsers.map((user) => ({
      label: user.email || "",
      value: user.email,
    }));
  }, [simpleUsers]);

  return (
    <RightDrawer
      open={open}
      onClose={onClose}
      title="Create a custom tariff"
      footer={
        <div className={styles.actions}>
          <PbButton onClick={onClose} className={styles.action} size="md" color="secondary">
            Cancel
          </PbButton>
          <PbButton
            disabled={loading}
            loading={loading}
            onClick={handleSubmit(onCreate)}
            type="submit"
            className={styles.action}
            size="md"
            color="primary"
          >
            Create
          </PbButton>
        </div>
      }
    >
      <form onSubmit={handleSubmit(onCreate)}>
        <div className={styles.check}>
          <Checkbox
            checked={isTrial}
            onChange={() => setValue("isTrial", !isTrial)}
            name="isTrial"
            id="isTrial"
            label="Add a trial period"
          />
        </div>
        {isTrial && (
          <ControlledText
            className={styles.field}
            label="Trial validity period, days"
            control={control}
            name="trialDays"
            type="number"
          />
        )}
        <ControlledText
          required
          className={styles.field}
          label="Tariff name"
          control={control}
          name="name"
          type="text"
        />
        <div className={styles.grid2fr}>
          <ControlledText
            required
            className={styles.field}
            label="Tariff storage, TB"
            control={control}
            name="storage_size"
            type="number"
          />
          <ControlledText
            required
            className={styles.field}
            label="Tariff transfer, TB"
            control={control}
            name="bandwidth"
            type="number"
          />
        </div>
        <div className={styles.grid2fr}>
          <ControlledText
            required
            className={styles.field}
            label="Trial Tariff storage, TB"
            control={control}
            name="trial_storage_limit"
            type="number"
          />
          <ControlledText
            required
            className={styles.field}
            label="Trial Tariff transfer, TB"
            control={control}
            name="trial_transfer_limit"
            type="number"
          />
        </div>
        <div className={styles.grid2fr}>
          <ControlledText
            required
            className={styles.field}
            label="Additional Storage, $ / GB"
            control={control}
            name="cost_storage_gb"
            type="number"
          />
          <ControlledText
            required
            className={styles.field}
            label="Additional Transfer, $ / GB"
            control={control}
            name="cost_bandwidth_gb"
            type="number"
          />
        </div>
        <ControlledText
          required
          className={styles.field}
          label="Price, $/month"
          control={control}
          name="price"
          type="number"
        />
        <ControlledText
          textArea
          required
          className={styles.field}
          label="Description"
          control={control}
          name="description"
          type="text"
        />
        <SelectMulti
          label="Add users"
          className={styles.select}
          onChange={(value) => setSelectedEmails(value)}
          options={options}
        />
      </form>
    </RightDrawer>
  );
};
