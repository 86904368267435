import { PayloadAction } from "@reduxjs/toolkit";
import { usersPageApi } from "api/UsersPageApi";
import { toast } from "react-toastify";
import { call } from "redux-saga/effects";
import { parseError } from "shared/src/helpers/errorHandler";
import { CreateInvoiceActionType } from "../userPageActions";

export function* createInvoicePartnerSaga({ payload }: PayloadAction<CreateInvoiceActionType>) {
  const { onSuccess, data, userId } = payload;
  try {
    if ("email" in data) {
      const res = yield call([usersPageApi, usersPageApi.createInvoicePartner], userId, data);
      onSuccess?.(res);
    }
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
  }
}
