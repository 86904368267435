import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatisticsCommon } from "models/StatisticsCommon";

type StatisticsState = {
  common?: StatisticsCommon;
};

const initialState: StatisticsState = {
  common: undefined,
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setStatisticsCommon(state, action: PayloadAction<StatisticsCommon>) {
      return {
        ...state,
        common: action.payload,
      };
    },
  },
});

export const statisticsReducer = statisticsSlice.reducer;
export const { setStatisticsCommon } = statisticsSlice.actions;
