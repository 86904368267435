import { customTariffsApi } from "api/CustomTariffsApi";
import { SimpleUser } from "models/User";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { setSimpleUsers } from "redux/customTariffsPage/customTariffsPageSlice";
import { parseError } from "shared/src/helpers/errorHandler";

export function* getSimpleUserListSaga() {
  try {
    const simpleList: SimpleUser[] = yield call([customTariffsApi, customTariffsApi.getSimpleUsersList]);
    yield put(setSimpleUsers(simpleList));
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  }
}
