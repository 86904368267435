import { format } from "date-fns";
import { Download, EmptyPage } from "iconoir-react";
import React from "react";
import { Heading } from "shared/src/components/Heading/Heading";
import { RightDrawer } from "shared/src/components/RightDrawer/RightDrawer";
import { Ticket } from "shared/src/models/TicketDetails";
import styles from "./ticketDrawer.module.scss";

type TicketDrawerProps = {
  onClose: () => void;
  open: boolean;
  ticket?: Ticket;
};
export const TicketDrawer: React.FC<TicketDrawerProps> = ({ open, onClose, ticket }) => {
  const getFileName = (file: string) => {
    const arr = file.split("/");
    return arr[arr.length - 1];
  };
  return ticket ? (
    <RightDrawer
      subHeader={format(ticket.createdAt, "dd.MM.yyyy HH:MM")}
      open={open}
      onClose={onClose}
      title={`Ticket ${ticket.subject}`}
    >
      <div>
        <Heading variant="h3">General information</Heading>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.tableTr}>
              <td className={styles.cellTitle}>Topic</td>
              <td>{ticket.topic}</td>
            </tr>
            <tr className={styles.tableTr}>
              <td className={styles.cellTitle}>Subtopic</td>
              <td>{ticket.subTopic}</td>
            </tr>
            <tr className={styles.tableTr}>
              <td className={styles.cellTitle}>Subject</td>
              <td>{ticket.subject}</td>
            </tr>
            <tr className={styles.tableTr}>
              <td className={styles.cellTitle}>Email</td>
              <td>{ticket.email}</td>
            </tr>
            <tr className={styles.tableTr}>
              <td className={styles.cellTitle}>Question</td>
              <td>{ticket.ticketDetails}</td>
            </tr>
          </tbody>
        </table>
        {ticket.file.length > 0 && (
          <>
            <Heading className={styles.mb32} variant="h3">
              Attached files
            </Heading>
            {ticket.file.map((file, idx) => (
              <div className={styles.fileBlock} key={idx}>
                <div className={styles.fileInfo}>
                  <EmptyPage className={styles.fileInfoIcon} color="#6E7E9D" />
                  <div className={styles.fileName}>{getFileName(file)}</div>
                </div>
                <a target="_blank" href={file} className={styles.downloadFile}>
                  <Download width={20} fontSize={10} color="#FFFFFF" />
                </a>
              </div>
            ))}
          </>
        )}
      </div>
    </RightDrawer>
  ) : null;
};
