import { NavigationSideBar } from "components/NavigationSideBar/NavigationSideBar";
import React, { useEffect } from "react";
import "react-day-picker/dist/style.css";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCatalogsAction } from "redux/catalogs/catalogActions";
import { RouteEnums } from "router/routeEnums";
import styles from "./layout.module.scss";

type LayoutProps = {
  showLayout?: boolean;
};

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children, showLayout }) => {
  const location = useLocation();
  const show = showLayout || location.pathname !== RouteEnums.login;
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      dispatch(getCatalogsAction());
    }
  }, [show]);

  return (
    <div className={styles.container}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
      {show && <NavigationSideBar />}
      <Outlet />
    </div>
  );
};
