import { Cancel } from "iconoir-react";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import { DrawerProps } from "rc-drawer/es/Drawer";
import React from "react";
import { PbButton } from "../PbButton/PbButton";
import styles from "./RightDrawer.module.scss";

type RightDrawerProps = DrawerProps & {
  children: React.ReactNode | string;
  title: string;
  subHeader?: React.ReactNode | string;
  footer?: React.ReactNode | string;
};

export const RightDrawer: React.FC<RightDrawerProps> = ({ onClose, open, children, title, subHeader, footer }) => {
  const width = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? "100%"
    : 550;

  return (
    <Drawer width={width} open={open} placement="right" onClose={onClose}>
      <div className={styles.drawerWrap}>
        <div className={styles.header}>
          <div className={styles.headerTitleWrap}>
            <span className={styles.headerTitle}>{title}</span>{" "}
            <Cancel className={styles.headerClose} color="#6E7E9D" onClick={onClose} />
          </div>
          {subHeader && <div className={styles.subHeader}>{subHeader}</div>}
        </div>
        <div className={styles.content}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </Drawer>
  );
};

type RDConfirmFooterProps = {
  onClose: () => void;
  submit: () => void;
  pending?: boolean;
  button?: string;
};

export const RDCFooter: React.FC<RDConfirmFooterProps> = (props) => {
  const { onClose, submit, button = "Create", pending } = props;

  return (
    <div className={styles.confirmFooter}>
      <PbButton onClick={onClose} className={styles.confirmFooterBtn} size="md" color="secondary">
        Cancel
      </PbButton>
      <PbButton
        loading={pending}
        onClick={submit}
        type="submit"
        className={styles.confirmFooterBtn}
        size="md"
        color="primary"
        disabled={pending}
      >
        {button}
      </PbButton>
    </div>
  );
};
