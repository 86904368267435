import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectCatalogState } from "./selectCatalogState";

export const selectTariffCatalog = createSelector([selectCatalogState], (state) => state.tariffCatalog);

export const selectCommonTariffs = createSelector([selectTariffCatalog], (tariffs) =>
  tariffs.filter((x) => !x.corporate)
);
export const selectCorporateTariffs = createSelector([selectTariffCatalog], (tariffs) =>
  tariffs.filter((x) => x.corporate)
);

export const useGetRelatedCatalog = (corporate: boolean) => {
  const corpTariffs = useSelector(selectCorporateTariffs);
  const commonTariffs = useSelector(selectCommonTariffs);
  return useMemo(() => (corporate ? corpTariffs : commonTariffs), [corporate, corpTariffs, commonTariffs]);
};

export const useGetTariffFromCatalog = () => {
  const tariffsCatalog = useSelector(selectTariffCatalog);

  return (tariffId?: number | null) => {
    return tariffsCatalog.find((x) => x.id === tariffId);
  };
};
