import clsx from "clsx";
import { format } from "date-fns";
import React, { useState } from "react";
import { TicketDrawer } from "shared/src/components/TicketsTable/TicketDrawer";
import { Ticket } from "shared/src/models/TicketDetails";
import styles from "./ticketsTable.module.scss";

type TicketsTableProps = {
  tickets?: Ticket[];
  loading: boolean;
};

enum TicketStatus {
  Open = "open",
  Close = "close",
}

export const TicketsTable: React.FC<TicketsTableProps> = ({ tickets = [], loading }) => {
  const [tabs, setTabs] = useState<TicketStatus>(TicketStatus.Open);
  const [activeTicket, setActiveTicket] = useState<Ticket | undefined>(undefined);

  if (tickets.length === 0) return null;

  return (
    <div className={styles.ticketsTable}>
      <div className={styles.headerBlockTabsTitleRow}>
        <div className={styles.headerBlockTabs}>Tickets</div>
        <div className={styles.tabs}>
          <div
            className={clsx(styles.tab, tabs === TicketStatus.Open && styles.tabActive)}
            onClick={() => setTabs(TicketStatus.Open)}
          >
            Open
          </div>
          <div
            className={clsx(styles.tab, tabs === TicketStatus.Close && styles.tabActive)}
            onClick={() => setTabs(TicketStatus.Close)}
          >
            Closed
          </div>
        </div>
      </div>
      <table className={styles.table}>
        <tbody>
          {tickets
            .filter((ticket) => ticket.status === tabs)
            .map((ticket) => (
              <tr onClick={() => setActiveTicket(ticket)} className={styles.ticketTr} key={ticket.id}>
                <td className={clsx(styles.ticketTd, styles.topic)}>
                  <span>{ticket.topic}</span>
                </td>
                <td className={clsx(styles.ticketTd, styles.subject)}>
                  <div>{ticket.subject}</div>
                </td>
                <td className={clsx(styles.ticketTd, styles.date)}>
                  <div>{format(ticket.createdAt, "dd.MM.yyyy HH:MM")}</div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <TicketDrawer open={!!activeTicket} onClose={() => setActiveTicket(undefined)} ticket={activeTicket} />
    </div>
  );
};
