import { format } from "date-fns";
import { AccessKey } from "models/AccessKey";
import styles from "pages/UserInfo/userInfo.module.scss";
import React from "react";
import { CommonTable, TableItem } from "shared/src/components/CommonTable/CommonTable";
import { Heading } from "shared/src/components/Heading/Heading";

type AccessKeysBlockProps = {
  loading: boolean;
  accessKeys?: AccessKey[];
};

export const AccessKeysBlock: React.FC<AccessKeysBlockProps> = ({ accessKeys = [], loading }) => {
  const items: TableItem<AccessKey>[] = [
    {
      label: "Active",
      render: (x) => <>{x.active ? "Active" : "Not Active"}</>,
    },
    {
      label: "Date",
      render: (x) => <>{format(x.createdAt, "P")}</>,
    },
    {
      label: "Secret Key",
      render: (x) => <>{x.secretKey}</>,
    },
  ];
  return (
    <div>
      <Heading className={styles.accessKeysTitle} variant="h2">
        Access Keys
      </Heading>
      <CommonTable loading={loading} className={styles.accessKeysTable} items={items} idKey="id" values={accessKeys} />
    </div>
  );
};
