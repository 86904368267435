const env = process.env;

type EnvValue<T extends string | boolean> = T;

const setupEnv = <T extends string | boolean>(defaultValue: T, envKey?: string): EnvValue<T> => {
  return (envKey as EnvValue<T>) || defaultValue;
};

type AppConfigStruct = {
  environment: string;
  urlAuthNode: string;
  apiUrl: string;
  isProd: boolean;
};

const LocalConfig: AppConfigStruct = {
  environment: "development",
  urlAuthNode: setupEnv("http://localhost:3000"),
  apiUrl: setupEnv("http://localhost:3333"),
  isProd: setupEnv(false),
};
const DevConfig: AppConfigStruct = {
  environment: "development",
  urlAuthNode: setupEnv("https://auth.petabox-dev.dccore.tech", env.REACT_APP_AUTHNODE_URL),
  apiUrl: setupEnv("https://dhb-api.petabox-dev.dccore.tech", env.REACT_APP_API_URL),
  isProd: setupEnv(false),
};

const StageConfig: AppConfigStruct = {
  environment: "staging",
  urlAuthNode: setupEnv("https://auth.petabox.dev", env.REACT_APP_AUTHNODE_URL),
  apiUrl: setupEnv("https://dhb-api.petabox.dev", env.REACT_APP_API_URL),
  isProd: setupEnv(true),
};

const ProductionConfig: AppConfigStruct = {
  environment: "production",
  urlAuthNode: String(env.REACT_APP_AUTHNODE_URL || ""),
  apiUrl: String(env.REACT_APP_API_URL || ""),
  isProd: String(env.REACT_APP_ENV) === "production",
};

const setupConfig = (): AppConfigStruct => {
  const appEnv = String(env.REACT_APP_ENV || "production");
  switch (true) {
    case appEnv === "production": {
      return ProductionConfig;
    }
    case appEnv === "development": {
      console.log({ DevConfig });
      return DevConfig;
    }
    case appEnv === "staging": {
      console.log({ StageConfig });
      return StageConfig;
    }
    case appEnv === "local": {
      console.log({ LocalConfig });
      return LocalConfig;
    }
    default: {
      return ProductionConfig;
    }
  }
};

export const AppConfig = setupConfig();
